.project-editor-view {
  &.data-view {
    .row {
      align-items: center;

      &.active {
        padding: 0;
      }
    }

    .data-view__common {
      min-height: 62.5px;
      display: flex;
      align-items: center;
    }

    .form__input {
      margin-bottom: 0;
    }

    .form__select {
      margin-bottom: 0;
      display: inline-block;
    }
  }
}
