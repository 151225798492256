.footer {
  background-color: $gray;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-shrink: 0; //For sticky footer

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.footer__logo-list {
  display: flex;
  gap: 30px;

  @include media-breakpoint-up(md) {
    gap: 40px;
  }
}

.footer__logo {
  display: block;
}

.footer__logo--crb {
  width: 193px;

  @include media-breakpoint-up(md) {
    width: 210px;
  }

  @include media-breakpoint-up(xl) {
    width: 200px;
  }

  @include media-breakpoint-up(xxl) {
    width: 226px;
  }
}

.footer__logo--werk {
  width: 91px;
  margin-left: auto;

  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    width: 100px;
  }

  @include media-breakpoint-up(xl) {
    width: 95px;
  }

  @include media-breakpoint-up(xxl) {
    width: 108px;
  }
}

.footer__logo--sia {
  width: 210px;

  @include media-breakpoint-up(sm) {
    margin-top: revert;
    margin-left: revert;
    margin-right: revert;
  }

  @include media-breakpoint-up(md) {
    width: 225px;
  }

  @include media-breakpoint-up(xl) {
    width: 235px;
  }

  @include media-breakpoint-up(xxl) {
    width: 240px;
  }
}

.footer__logo--bfs {
  width: 290px;

  @include media-breakpoint-up(md) {
    width: 311px;
  }

  @include media-breakpoint-up(xl) {
    width: 300px;
    margin-top: revert;
    margin-left: revert;
    margin-right: revert;
  }

  @include media-breakpoint-up(xxl) {
    width: 332px;
  }
}
