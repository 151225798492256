.box {
  border: 5px solid $green;
  padding: 15px;

  @include media-breakpoint-up(md) {
    border-width: 10px;
    padding: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding: 50px;
  }
}
