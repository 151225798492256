.wizard-save-button {
  background-color: $crb-white;
}

.hvalue-row {
  display: inline-flex;
  width: 100%;
  background-color: $crb-white;
}

.h-value-value {
  flex: 1 0 50%;
  display: inline-flex;
  min-height: 55px;
  justify-content: flex-end;
}
