.ebkph-tree-view__header {
  .ebkp-tree-filter-entry .form__input,
  .ebkp-tree-filter-entry .form__checkbox {
    margin: 0;
  }

  .ebkp-tree-view-filter-menu section:first-of-type {
    margin-top: 0 !important;
  }

  .ebkp-tree-view-filter-menu__section-icon {
    height: 20px;
    width: 20px;
    margin-right: 25px;
  }

  .ebkp-tree-view-filter-menu__section-entries {
    margin-left: 45px;
  }

  .menu-button,
  .icon-button {
    height: 40px;
    width: 40px;
  }
}
