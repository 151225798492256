@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg.spinner {
  animation: spin 1s infinite linear;
}
