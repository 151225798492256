.energy-tree-lock {
  position: absolute;
  right: 2.5rem;
  top: 50%;
  transform: translateY(calc(-50% + 0.5rem));
  z-index: 1;
}

.energy-tree-status {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);

  width: 8px;
  height: 8px;

  border-radius: 50%;

  background-color: lightgray;

  &__ok {
    background-color: $crb-states-success;
  }

  &__nok {
    background-color: $crb-states-error-bad;
  }
}

.energy-tree-node {
  .form__input {
    padding-right: 1rem;
  }

  .form__input-field {
    text-align: right;
    padding-right: 36px;
  }
}
