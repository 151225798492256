$color-quintiles-outer: #c3d0c8;
$color-quintiles-middle: #91a59a;
$color-quintiles-inner: #718d7f;

.box-plot {
  position: relative;
  background-color: $gray;
  height: 1em;
}

.box-plot * {
  transition: all 0.3s ease-out;
  transition-delay: 0.25s;
  opacity: 1;
}

@keyframes loading-animation {
  0% {
    background-color: $gray;
  }
  50% {
    background-color: $color-quintiles-outer;
  }
  100% {
    background-color: $gray;
  }
}

.box-plot.box-plot--loading {
  animation-name: loading-animation;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
}

.box-plot.box-plot--loading * {
  opacity: 0;
}

.box-plot.box-plot--empty {
  .box-plot__quintiles,
  .box-plot__median,
  .box-plot__label {
    display: none;
  }
}

.box-plot__median {
  position: absolute;
  height: 110%;
  top: -5%;
  border-left: 1px dashed $dark-gray;
}

.box-plot__quintiles {
  position: absolute;
  height: 1em;
}

.box-plot__quintiles--outer {
  background-color: $color-quintiles-outer;
}

.box-plot__quintiles--middle {
  background-color: $color-quintiles-middle;
}

.box-plot__quintiles--inner {
  background-color: $color-quintiles-inner;
}

.box-plot__label {
  position: absolute;
  color: $dark-gray;
  top: 100%;
  left: 50%;
  font-size: 12px;
  transform: translate(-40%, 0);
  white-space: nowrap;
  overflow: visible;
}

.box-plot__empty-label {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: $dark-gray;
  line-height: 1.2em;
  vertical-align: middle;
}
