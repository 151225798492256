.info-banner {
  background-color: #007cab;
  color: white;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  * {
    margin-bottom: 0;
  }
}
