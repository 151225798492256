@import url('https://fast.fonts.net/cssapi/6e28d51e-229f-40da-a379-40f451dc9392.css');

@font-face {
  font-family: 'Apercu';
  src: local('Apercu Regular'), local('ApercuRegular'), url('./fonts/apercu_regular.woff2') format('woff2'),
    url('./fonts/apercu_regular.woff') format('woff'), url('./fonts/apercu_regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: local('Apercu Medium'), local('ApercuMedium'), url('./fonts/apercu_medium.woff2') format('woff2'),
    url('./fonts/apercu_medium.woff') format('woff'), url('./fonts/apercu_medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
