.project-importer {
  display: grid;
  place-items: center;
  font-size: 18px;

  gap: 2rem;

  p {
    margin-bottom: 0;
  }

  &__area {
    border: 1px dashed black;
    border-radius: 0.5rem;

    display: grid;
    place-items: center;

    padding: 2rem 4rem;

    text-align: center;
    font-size: 24px;
  }

  &__result {
    text-align: left;
    width: 100%;
  }
}
