.csv-importer-overlay {
  padding: 10%;
}

.csv-importer {
  background: white;
  border: 2px solid #fff;
}

.csv-preview {
}
