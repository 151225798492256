.regulation-statistics {
  display: flex;
}

.regulation-statistics__label {
  margin: auto 0 auto 5px;
  line-height: 1em;
  color: $dark-gray;
}

.regulation-statistics__mean {
  float: right;
  transition: all 0.3s ease-out;
  transition-delay: 0.25s;
}

.regulation-statistics__mean--hidden {
  opacity: 0;
}

.regulation-statistics__spacing,
.regulation-statistics__label {
  width: 20%;
}

.regulation-statistics__label,
.regulation-statistics__statistic {
  margin-left: 5px;
}

.regulation-statistics__statistic {
  width: 60%;
  height: 2em;
}
