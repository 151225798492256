.tag-editor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(60%, max(100%, 400px));
  transform: translate(-50%, -50%);

  background-color: white;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;

    padding: 0.5rem 1rem;

    h3 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    padding: 1rem;

    overflow-y: auto;
    max-height: 50vh;
  }
}
