.image-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 1;
}

.image-box__close-button {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 40px;
  padding: 0;
  font-weight: bold;
  color: white;
  background: none;
  border: none;
  outline: none;
  width: 45px;
  height: 45px;
}

.image-box__container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  max-width: 90%;
  max-height: 90%;
  padding: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-box__image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  user-select: none;
}

.image-box__previous,
.image-box__next {
  top: 0;
  bottom: 0;
  width: 100px;
  position: absolute;
  background: rgba(127, 127, 127, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 40px;

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    border: solid 4px transparent;
    transform: rotateZ(45deg);
  }

  &:hover {
    background: rgba(127, 127, 127, 0.2);
    box-shadow: 0 6px 12px 0 $dark-gray;
  }
}

.image-box__previous {
  left: 0;

  &::after {
    border-bottom-color: $white;
    border-left-color: $white;
    margin-left: 40px;
  }
}

.image-box__next {
  right: 0;

  &::after {
    border-top-color: $white;
    border-right-color: $white;
    margin-left: 20px;
  }
}
