@mixin overlay {
  position: fixed;
  z-index: 100;
}

.dialog__overlay {
  @include overlay();

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.dialog__content {
  background-color: $white;
  min-width: 350px;
  min-height: 200px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.dialog__header {
  font-size: 28px;
  padding: 16px;
  border-bottom: solid 1px black;
}

.dialog__body {
  font-size: 24px;
  padding: 16px;
  flex-grow: 1;
}

.dialog__footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
}

.toast__content {
  @include overlay();

  top: 10%;
  left: 50%;

  width: 300px;
  min-height: 200px;

  margin-left: -150px;

  background-color: $white;
  box-shadow: 0 0 10px 10px #333;
}

.toast__header {
  font-size: 28px;
  padding: 16px;
  border-bottom: solid 1px black;
}

.toast__body {
  font-size: 24px;
  padding: 16px;
  flex-grow: 1;
}
