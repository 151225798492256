.rc-slider.range-slider {
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  background: $crb-grey-10;
  height: 3em;
  padding: 0;

  .rc-slider {
    &-rail {
      display: none;
    }

    &-track {
      display: none;
    }

    &-step {
      display: none;
    }

    &-handle {
      border: none;
      border-radius: 0;
      background-image: url('./slider-handle.svg');
      color: $white;
      margin: 0;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      box-shadow: none !important;

      &:hover {
        box-shadow: none;
      }

      &::before {
        display: block;
        content: '';
        background: $black;
        width: 3px;
        height: 54px;
        position: relative;
        left: 11px;
        top: -14px;
      }

      &::after {
        display: block;
        content: attr('aria-valuenow');
        color: red;
      }
    }

    &-tooltip {
      padding: 0;

      &-inner {
        border-radius: 0;
        background: $black;
        color: $white;
        font-weight: bold;
        padding: 0.18em 0.3em;
        font-size: 15px;
        line-height: 1;
        height: 1.4em;
      }

      &-arrow {
        display: none;
      }
    }
  }

  &--disabled {
    background-color: $crb-states-locked;

    .rc-slider-handle {
      background: none;
    }

    .rc-slider-tooltip {
      top: 17px !important;
    }
  }
}

.rc-slider--hidden {
  [role='slider'] {
    visibility: hidden;
  }

  .rc-slider-tooltip {
    visibility: hidden;
  }
}

.slider-wrapper {
  position: relative;
  width: 60%;

  .slider-value {
    position: absolute;
    top: -20px;
    font-size: small;
    color: #7e7979;
    font-weight: bolder;

    &.slider-value-right {
      right: 0;
    }
  }

  .rc-slider {
    width: 100%;
  }
}
