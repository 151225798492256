#tableau-container {
  position: relative;
}

#tableau-container[data-scale] iframe {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) translate(0, 50%);
}
