.wizard-nav {
  margin-top: 16px;
  display: flex;

  > * {
    flex: 1 1 100%;

    border: solid 2px black;
    border-left-color: transparent;
    border-right-color: transparent;
    margin: 0 5px;
    outline: none;
    padding: 0;
    height: inherit;

    &:first-child {
      border-left-color: black;
      margin-left: 0;

      svg:first-child {
        display: none;
      }
    }

    &:last-child {
      border-right-color: black;
      margin-right: 0;

      svg:last-child {
        display: none;
      }
    }
  }
}

.wizard-nav-item {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;

  &:focus {
    outline: none !important;
  }

  &__svg-arrow {
    position: absolute;
    top: -2px;

    path {
      stroke: $black;
      stroke-width: 2;
      fill: $white;
    }

    &:first-child {
      left: -2px;
      z-index: 1;
    }

    &:last-child {
      right: 0;
      transform: translateX(100%);
      z-index: 2;

      path {
        fill: $white;
      }
    }
  }

  &--active {
    background-color: $green;
    font-weight: bold;

    .wizard-nav-item__svg-arrow:last-child path {
      fill: $green;
    }
  }

  &__label {
    flex: 1;
  }
}
