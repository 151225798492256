.image-canvas {
  position: relative;
  height: 100%;
  width: 100%;

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-canvas-error {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 80%;

    transform: translate(-50%, -50%);

    text-align: center;
  }
}
