.content-slider {
  display: flex;

  &__scroll-button {
    border: none;
    border-radius: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 50px;
    color: $black;
    outline: none;
  }

  &__slider {
    flex: 1;
    overflow: hidden;
    margin: 0;
  }

  &__content {
    white-space: nowrap;
  }
}
