.volume-evaluation-wizard {
  .building-plan-wrapper {
    overflow: hidden;
    height: 630px;
  }

  .model-creation-form,
  .model-calculation-results {
    thead {
      th:nth-child(1) {
        width: 42px;
      }

      th:nth-child(3) {
        width: 20%;
      }

      th:nth-child(4) {
        width: 20%;
      }
    }

    .table-border-section {
      td {
        border-top: 1px black solid;
      }
    }
  }
}

.building-editor--wizard .building-plan-importer {
  display: grid;
  grid-template:
    [row1start] 'nav-actions' [row1-end]
    [row2-start] 'error' [row2-end]
    [row3-start] 'content' [row3-end]
    [row4-start] 'instructions' [row4-end]
    [row5-start] 'nav-state' [row5-end];
  grid-row-gap: 16px;
}
