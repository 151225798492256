.progress {
  border-radius: 0;
  height: 50px;
}

.progress-bar {
  color: $black;
  font-size: 16px;
}

.progress-bar.bg-secondary {
  color: $white;
}

@at-root .progress-bar-tooltip {
  .tooltip-inner {
    max-width: none !important;
  }
}
