.project-exporter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  place-items: center;

  &__spinner {
    display: grid;
    place-items: center;
    margin-bottom: 1rem;
  }

  &__spinner-text {
    position: absolute;
    margin: 0;
    font-size: 24;
    font-weight: bold;
    text-align: center;
  }

  &--completed {
    .project-exporter__spinner-text {
      position: relative;
    }
  }
}
