.project-editor-view.cost-view {
  .table-headers .col,
  .regulation-tree .col {
    display: flex;
    align-items: center;

    font-weight: 500;

    overflow: hidden;
    word-break: break-word;
    white-space: break-spaces;
  }

  .cost--inactive {
    .cost-value {
      padding-right: 2em;

      .cost-value-lock {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .cost-value {
    .form__input-field {
      padding-right: 2em;
      text-align: right;
    }
  }

  .cost {
    min-height: 62.5px;

    .cost-value-lock {
      position: absolute;
      right: 0;
      height: 2em;
      width: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      transform: translateY(-10px);
      top: 50%;
    }

    .status-bubble {
      position: relative;
      height: 8px;
      width: 8px;

      border-radius: 50%;

      border: 2px solid $crb-grey-40;

      &[data-status='ok'] {
        background-color: $crb-states-success;
        border: none;
      }

      &[data-status='warn'] {
        background-color: $crb-states-warning;
        border: none;
      }

      &[data-status='nok'] {
        background-color: $crb-states-error-bad;
        border: none;
      }
    }
  }
}

.wizard-underway-warning {
  color: $crb-states-error-bad;

  &__text {
    margin-left: 10px;
  }
}
