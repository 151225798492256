.sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 100%;
  right: 0;
  width: 100%;
  background-color: $white;
  padding: 15px 45px;
  overflow-y: scroll;
  transform: translate(100%, 0);
  transition: transform 0.3s;

  @include media-breakpoint-up(sm) {
    padding: 30px 90px;
  }

  @include media-breakpoint-up(md) {
    width: 80%;
  }

  @include media-breakpoint-up(lg) {
    width: 60%;
  }

  @include media-breakpoint-up(xl) {
    padding: 60px 90px;
    width: 50%;
  }

  @include media-breakpoint-up(xxl) {
    width: 40%;
  }
}

.sidebar--open {
  transform: translate(0, 0);
}

.sidebar__close {
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  outline: none !important;

  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: $black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: 50% 50%;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.sidebar__header {
  margin-top: -24px;

  #userinfo__username {
    padding-right: 0.5rem;
  }
}

.sidebar__content {
  margin-top: 45px;

  @include media-breakpoint-up(md) {
    margin-top: 75px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 75px;
  }
}
