$button-font-size: 16px;
$button-border-width: 2px;
$button-padding-sm-y: 7.5px;
$button-padding-y: 10px;

.button,
.menu-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  font-size: $button-font-size;
  line-height: 1;
  padding: $button-padding-sm-y 15px;
  border: $button-border-width solid $black;
  transition: all 0.3s;
  color: $black;
  text-decoration: none;
  outline: none !important;

  @include media-breakpoint-up(md) {
    padding: $button-padding-y 40px;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    color: $black;
    background-color: $green;
    text-decoration: none;
  }

  &[disabled] {
    border-color: $gray;
    color: $gray;
  }

  &.passive-button {
    background-color: $light-green;
    border-left: none;
    cursor: default;

    &:hover {
      background-color: $light-green;
    }
    &:active {
      background-color: $light-green;
      color: inherit;
    }
    &:focus {
      background-color: $light-green;
      color: inherit;
    }
  }

  &[data-active='true'] {
    background-color: $green;
    color: inherit;
  }
}

.menu-button,
.icon-button {
  &:focus {
    outline: none !important;
  }
}

.link-button[data-disabled='true'] {
  color: #999;
  cursor: not-allowed;
}

.link-button--no-underline {
  text-decoration: none !important;
}

button:focus {
  outline: $crb-outline !important;
}

.menu-button {
  width: $button-font-size + 2 * $button-border-width + 2 * $button-padding-sm-y;
  height: $button-font-size + 2 * $button-border-width + 2 * $button-padding-sm-y;
  padding: 0 !important;

  @include media-breakpoint-up(md) {
    width: $button-font-size + 2 * $button-border-width + 2 * $button-padding-y;
    height: $button-font-size + 2 * $button-border-width + 2 * $button-padding-y;
  }
}

.menu-button-container {
  position: relative;
}

.menu-button__menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: $white;
  cursor: default;
  border: 2px solid $black;
  padding: 16px 24px;
  z-index: 100;
  width: min-content;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.menu-button__menu-close {
  cursor: pointer;
  align-self: flex-end;
}

.button:focus {
  outline: none !important;
}

button:focus {
  outline: $crb-outline !important;
}

button:disabled {
  cursor: not-allowed;
}
