$row-height: 1rem;

.forest-diagram,
.forest-diagram * {
  cursor: default;
}

.forest-diagram {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.forest-diagram__part {
  display: flex;
  width: 100%;
}

.forest-diagram-row {
  width: 100%;
  height: $row-height;
  display: flex;
}

.forest-diagram__cell {
  height: $row-height;
  background-color: $green;
  border: 1px $gray solid;

  display: grid;
  place-items: center;

  font-size: 0.75rem;
  line-height: $row-height;
  overflow: hidden;

  &--combined {
    border-right-width: 0px;
  }

  &--invalid {
    @extend .forest-diagram__cell;
    background-color: $red;

    &.forest-diagram__cell--combined {
      border-left-width: 0px;
      border-right-width: 1px;
    }
  }

  &--empty {
    display: none;
  }
}
