h1 + p {
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-top: 25px;
  }
}

h2 + p,
h3 + p {
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    margin-top: 15px;
  }
}

.xs-push-top {
  @include media-breakpoint-only(xs) {
    margin-top: 15px;
  }
}

.xs-big-push-top {
  @include media-breakpoint-only(xs) {
    margin-top: 30px;
  }
}

.sm-push-top {
  @include media-breakpoint-only(sm) {
    margin-top: 30px;
  }
}

.md-push-top {
  @include media-breakpoint-only(md) {
    margin-top: 30px;
  }
}

.lg-push-top {
  @include media-breakpoint-only(lg) {
    margin-top: 30px;
  }
}

.xl-push-top {
  @include media-breakpoint-only(xl) {
    margin-top: 30px;
  }
}

.small-gutter-top {
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }
}

.gutter-top {
  margin-top: 15px;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 30px;
  }
}

.medium-gutter-top {
  margin-top: 25px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 40px;
  }
}

.small-push-top {
  margin-top: 35px;

  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 75px;
  }
}

.small-push-bottom {
  margin-bottom: 35px;

  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 75px;
  }
}

.very-small-push-top {
  margin-top: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 1rem;
  }
}

.very-small-push-bottom {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }
}

.small-padding-top {
  padding-top: 35px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 75px;
  }
}

.small-padding-bottom {
  padding-bottom: 35px;

  @include media-breakpoint-up(md) {
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 75px;
  }
}

.push-top {
  margin-top: 35px;

  @include media-breakpoint-up(md) {
    margin-top: 75px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 150px;
  }
}

.push-bottom {
  margin-bottom: 35px;

  @include media-breakpoint-up(md) {
    margin-bottom: 75px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 150px;
  }
}
