@media print {
  .header:has(+ main #estimaton-wizard),
  .wrapper:has(#estimaton-wizard) + .footer,
  #estimaton-wizard .wizard-nav,
  #estimaton-wizard .status-indicator,
  #estimaton-wizard button,
  #estimaton-wizard .tableau-wrapper {
    display: none !important;
  }

  #estimaton-wizard {
    .estimation-plausibility {
      padding-top: 15px !important; // reduce space in order to show 7 rows of categories in the fist page
    }

    .categories-tableau-container {
      margin-top: 15px !important;
      width: 100% !important;

      .category-content {
        width: 100% !important;
      }

      .category-row {
        break-inside: avoid;
      }
    }
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
