.building-plan-estimator {
  overflow: hidden;
  height: 350px;
  width: 350px;

  & canvas {
    height: 350px;
    width: 350px;
  }
}
