html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  color: black;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  font-family: $font-primary;
  font-weight: 300;
  hyphens: auto;

  @include media-breakpoint-up(md) {
    font-size: 17px;
    line-height: 25px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

html {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @include media-breakpoint-up(sm) {
    width: 100vw;
  }
}

body {
  display: flex; //For sticky footer
  flex-direction: column; //For sticky footer
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; //For sticky footer

  main {
    flex-grow: 1;
  }
}

h1 {
  font-family: $font-secondary;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;

  @include media-breakpoint-up(md) {
    font-size: 30px;
    line-height: 33px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 32px;
    line-height: 36px;
  }
}

h2 {
  font-family: $font-secondary;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 29px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 28px;
    line-height: 34px;
  }
}

.lead,
h3 {
  font-family: $font-secondary;
  font-size: 18px;
  line-height: 22px;

  @include media-breakpoint-up(md) {
    font-size: 19px;
    line-height: 21px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 24px;
  }
}

h4 {
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 20px;

  @include media-breakpoint-up(md) {
    font-size: 17px;
    line-height: 19px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 22px;
  }
}

a {
  color: $black;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $black;
    text-decoration: none;
  }
}

hr {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  background-color: black;
}

::selection {
  color: $black;
  background-color: $green;
}

.img--hoverable {
  transition: box-shadow 200ms linear;
  box-shadow: 0 0 0 transparent;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.33);
  }
}
