#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#tableau-container {
  overflow: hidden;
  min-height: 1000px;

  iframe {
    margin: 0 auto !important;
    min-height: 1000px;
  }
}

ul {
  list-style: none;
  padding-left: 0;
}

h2 {
  margin-bottom: 0;
}

a {
  text-decoration: underline !important;
}

th,
td {
  vertical-align: middle !important;
}

#project-editor {
  height: 100%;
  & > div.container {
    height: 100%;
  }
}

.project-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-editor-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-editor-view-content {
  flex-grow: 1;
}
