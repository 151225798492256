.project-editor-view {
  &.building-view {
    .new-floor {
      .floor {
        border: 2px solid #000;
      }
    }
  }
}

.building-view-primary {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10%;
}

.building-editor--project .building-plan-importer {
  display: grid;
  grid-template:
    [row1start] 'nav-state' [row1-end]
    [row2-start] 'instructions' [row2-end]
    [row3-start] 'error' [row3-end]
    [row4-start] 'content' [row4-end]
    [row5-start] 'nav-actions' [row5-end];
  grid-row-gap: 16px;
}
