.inset-0 {
  inset: 0;
}

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: #{$value};
  }
}

@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: $value) {
    .position-#{$breakpoint}-absolute {
      position: absolute;
    }

    .position-#{$breakpoint}-relative {
      position: relative;
    }
  }
}

.responsive {
  display: block;
  width: 100%;
}

.no-scroll {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-btn-unstyled {
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;
  margin: 0;
}

.hoverable {
  &:hover,
  &:focus,
  &:active {
    background-color: $gray;
    outline: none !important;
  }
}

[data-hover='true'] {
  background-color: $gray;
  outline: none !important;
}
