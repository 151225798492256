.form__input {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}

.form__input--error {
  &:after {
    content: '';
    background-color: $red;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.form__input-field {
  position: relative;
  display: block;
  border: none !important;
  border-radius: 0;
  appearance: none;
  padding-top: 23px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background: $gray;
  resize: none;

  &:disabled {
    cursor: not-allowed;
  }

  &[type='number'] {
    text-align: right;
    -moz-appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &[type='date'] {
    color: rgba(0, 0, 0, 0);
    padding-top: 21px;

    &:focus {
      color: unset;

      &::placeholder {
        opacity: 1;
      }
    }

    &::placeholder {
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &[type='date'].form__input-field--filled {
    color: unset;
  }

  &.uuid-input {
    font-size: 20px;
    width: calc(32ch + 30px);
  }

  &.invalid.touched {
    border-right: solid 10px $red !important;
  }
}

.form__input-field:focus {
  outline: none;
}

.form__input-label {
  display: inline-block;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  margin: 0;

  &__small {
    font-size: small;
  }
}

.form__input-label-content {
  color: $dark-gray;
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  width: 100%;
  transform: translate(0, -50%);
  transition: all 0.3s;
}

.form__input-field:focus + .form__input-label .form__input-label-content,
.form__input-field--filled + .form__input-label .form__input-label-content {
  font-size: 15px;
  top: 0;
  transform: translate(0, 0);
}

.form__checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  user-select: none;
  text-align: left;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .form__checkbox-checkmark {
      background-color: $green;
      &:after {
        display: block;
      }
    }
  }

  .form__checkbox-checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $gray;
    border: 1px solid $black;
    transform: translate(0, -50%);

    &:after {
      content: '';
      position: absolute;
      box-sizing: content-box;
      display: none;
      left: 50%;
      top: 45%;
      width: 4px;
      height: 8px;
      border: solid $black;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.form__radio {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .form__radio-checkmark {
      background-color: $green;
      &:after {
        display: block;
      }
    }
  }

  .form__radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $gray;
    border: 1px solid $black;
    border-radius: 50%;

    &:after {
      content: '';
      background-color: $black;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: content-box;
      display: none;
      height: 10px;
      width: 10px;
      transform: translate(-50%, -50%);
    }

    &--center {
      top: calc(50% - 11px);
    }
  }
}

.form__select {
  position: relative;
  display: block;
  border: none !important;
  border-radius: 0;
  appearance: none;
  padding: 15px;
  width: 100%;
  background: $gray;
  resize: none;
  outline: none !important;

  min-height: 52px;
  padding-top: 23px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 100%;

  &.form__select--disabled,
  &.form__select--disabled * {
    cursor: not-allowed;
  }

  &.form__select--filled {
    .form__select__label {
      font-size: 15px;
      top: 0;
      transform: translate(0, 0);
      left: 0;
      padding-left: 15px;
      line-height: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.form__select--no-label {
    padding-top: 19px;

    &::after {
      bottom: 10px;
    }
  }

  .form__select__label {
    z-index: 1;
    color: $dark-gray;
    position: absolute;
    left: 15px;
    top: 50%;
    display: block;
    width: 100%;
    transform: translate(0, -50%);
    transition: all 0.3s;
    margin-bottom: 0;
  }

  &::after {
    content: url('/images/icons/arrow-down.svg');
    position: absolute;
    bottom: 15px;
    right: 15px;

    width: 15px;
    height: 15px;

    transform: translateY(-50%);
  }

  &.form__search-select,
  &.form__tag-select,
  &.form__regulation-select {
    padding: 0;

    .values {
      [data-label] {
        position: relative;

        &:hover::after {
          display: inline;
        }
      }
    }

    .form__search-select__option,
    .form__tag-select__option,
    .form__regulation-select__option {
      position: relative;
      padding-right: 24px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      button {
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translate(50%, -50%);
        border: none;
        border-radius: 50%;

        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        outline: none;

        background: inherit;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .select-menu {
      margin-left: 0;
      width: 100%;
      transform: none;
    }

    .form__search-select__input,
    .form__tag-select__input,
    .form__regulation-select__input {
      position: relative;
    }
  }

  .select-option {
    position: relative;
    line-break: anywhere;
    word-break: break-word;
  }

  .select__option-title {
    font-size: 12px;
    color: $dark-gray;
    font-weight: 500;
    position: absolute;
    top: 5px;
  }

  &[data-focused='true'] {
    z-index: 10;
  }

  //Firefox dotted line fix
  color: rgba(0, 0, 0, 1); // Fixed by ti&m - TODO: validate change
  color: #000;
  text-shadow: 0 0 0 #000;

  overflow: visible;

  .select-menu {
    position: absolute;
    z-index: 100;
    margin-left: -15px;
    top: 100%;
    width: 100%;

    .select-options {
      position: absolute;
      background: #fff;
      width: 100%;
      // top: 51px;
      left: 0;

      z-index: 1000;
      max-height: 400px;
      overflow-y: auto;

      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &[data-align='center'] {
        text-align: center;
      }

      &[data-align='right'] {
        text-align: right;
      }

      .select-option {
        &:hover {
          background: $light-green;
        }
        &[data-selected='true'] {
          background: $green;
        }
        &[data-focused='true'] {
          background: $light-green;
        }
      }
    }

    &:required:invalid {
      font-weight: 300;
      color: $dark-gray;
    }
  }
}

.form__error {
  color: $red;
  font-family: $font-secondary;
}

.form__range {
  .range-input__mouse-input {
    position: relative;
  }

  .range-input__slider {
    position: absolute;
    height: 100%;
    width: 16px;
    top: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    z-index: inherit;

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .range-input__key-input {
    display: flex;
    justify-content: space-between;

    & input {
      width: 6ch;
      text-align: center;

      &:last-of-type {
        text-align: right;
      }
    }
  }
}
