.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba($light-green, 0.9);

  z-index: 100;
}
