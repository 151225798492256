.project-editor-view {
  &.measurement-view {
    .measurement {
      .form__input {
        margin-bottom: 0;
      }

      .measurement-value-lock {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translate(0px, -50%);
        z-index: 1;
      }

      .status-bubble {
        position: absolute;
        right: -1rem;
        top: 50%;
        transform: translate(0px, -50%);

        height: 8px;
        width: 8px;

        border-radius: 50%;

        border: 2px solid $crb-grey-40;

        &[data-status='ok'] {
          background-color: $crb-states-success;
          border: none;
        }

        &[data-status='warn'] {
          background-color: $crb-states-warning;
          border: none;
        }

        &[data-status='nok'] {
          background-color: $crb-states-error-bad;
          border: none;
        }
      }
    }
  }
}
