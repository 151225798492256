.building-plan + canvas {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.building-plan-wrapper {
  position: relative;
  height: 600px;
  min-height: 1px;
}

.building-editor-plan__building-plan-wrapper {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 10%;
  // height: 100%;
  max-height: 80vh;
  min-height: calc(300px + 3em + 40px);
}

.building-editor-form .model-creation-form,
.building-editor-form .contour-selection-form {
  flex-grow: 1;
}

.building-editor-form__actions {
  display: flex;
  gap: 1em;
}
