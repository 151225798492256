.draggable-list {
  width: 100%;

  .draggable-item {
    position: relative;
    width: 100%;
    box-sizing: content-box;

    &[draggable='true']:not([data-hide-icon='true']) {
      .drag-icon {
        position: absolute;
        display: block;

        right: 16px;
        top: 50%;

        transform: translateY(-50%);
      }
    }
  }
}
