@for $i from 1 to 24 {
  .col-24-#{$i} {
    max-width: #{100% / 24 * $i} !important;
  }

  @each $breakpoint-name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .col-#{$breakpoint-name}-24-#{$i} {
        max-width: #{100% / 24 * $i} !important;
      }
    }
  }
}

@each $breakpoint-name, $breakpoint in $grid-breakpoints {
  @each $size-name, $size in $sizes {
    @media (min-width: $breakpoint) {
      .h-#{$breakpoint-name}-#{$size-name} {
        height: $size;
      }

      .w-#{$breakpoint-name}-#{$size-name} {
        width: $size;
      }

      .mh-#{$breakpoint-name}-#{$size-name} {
        max-height: $size;
      }

      .mw-#{$breakpoint-name}-#{$size-name} {
        width: $size;
      }

      .vh-#{$breakpoint-name}-#{$size-name} {
        @if $size-name == auto {
          height: unset;
        } @else {
          height: #{str-slice('#{$size}', 1, -2)}vh;
        }
      }

      .vw-#{$breakpoint-name}-#{$size-name} {
        @if $size-name == auto {
          height: unset;
        } @else {
          width: #{str-slice('#{$size}', 1, -2)}vw;
        }
      }

      .mvh-#{$breakpoint-name}-#{$size-name} {
        @if $size-name == auto {
          max-height: unset;
        } @else {
          max-height: #{str-slice('#{$size}', 1, -2)}vh;
        }
      }

      .mvw-#{$breakpoint-name}-#{$size-name} {
        @if $size-name == auto {
          height: unset;
        } @else {
          max-width: #{str-slice('#{$size}', 1, -2)}vw;
        }
      }
    }
  }
}

@each $size-name, $size in $sizes {
  .vh-#{$size-name} {
    @if $size-name == auto {
      height: unset;
    } @else {
      height: #{str-slice('#{$size}', 1, -2)}vh;
    }
  }

  .mvh-#{$size-name} {
    @if $size-name == auto {
      max-height: unset;
    } @else {
      max-height: #{str-slice('#{$size}', 1, -2)}vh;
    }
  }
}
