.building-plan-importer {
  display: grid;
  grid-template:
    [row1start] 'nav-actions' [row1-end]
    [row2-start] 'error' [row2-end]
    [row3-start] 'content' [row3-end]
    [row4-start] 'instructions' [row4-end]
    [row5-start] 'nav-state' [row5-end];
  grid-row-gap: 16px;
}

.building-plan-importer__nav-state {
  grid-area: nav-state;
}

.building-plan-importer__nav-actions {
  grid-area: nav-actions;

  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.building-plan-importer__instructions {
  grid-area: instructions;
}

.building-plan-importer__error {
  grid-area: error;

  color: $red;
  margin-top: 16px;
  margin-bottom: 16px;

  padding: 0% 10%;
  text-align: center;

  &--floating {
    position: absolute;
    top: 0%;
    width: calc(100% - 32px);

    background: #ffffff;
    margin: 16px;

    padding: 16px 10%;

    z-index: 1000;
  }
}

.building-plan-importer__content {
  grid-area: content;

  position: relative;
  background-color: $primary;
  display: flex;
  justify-content: center;
  height: 40vh;
  padding: 3rem 0;
}

.building-plan-importer__content input {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.building-plan-importer__img {
  width: 60vw;
  max-width: 90%;
}

.building-plan-importer__error {
  color: $red;
  margin-top: 16px;
  margin-bottom: 16px;
}

.ifc-viewer {
  padding: 0px;
}
