.project-table {
  table {
    tr {
      th,
      td {
        vertical-align: middle;
      }
    }
  }
}

.project-list-item {
  &.disabled {
    background-color: $gray;
    color: $dark-gray;
  }
  &.selected {
    background-color: $light-green;
  }

  &__thumbnail {
    height: 100px;
  }

  &__werkkategorie-title {
    font-size: 12px;
    color: $dark-gray;
    font-weight: 500;
    top: 5px;
  }
}

.project-list-header {
  &--sortable {
    cursor: pointer;
  }

  &__content {
    display: inline-flex;
    width: 100%;
  }

  &__text {
    flex: 1;
  }

  &__sort-icon {
    align-self: center;
  }
}
