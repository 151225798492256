.lang-changer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5em;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__out-of-view {
    position: fixed;
    top: 8px;
    right: 8px;

    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding: 2px 8px;
    background-color: white;

    &--collapsed {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.lang-changer__lang {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.lang-changer__lang--active {
  text-decoration: underline;
  pointer-events: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}
