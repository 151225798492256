.project-editor-view {
  &.energy-view {
    .row {
      .form__input {
        margin-bottom: 0 !important;
      }
    }

    .energy-tree-metric-toggle {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
