@import 'regulation-statistics';

.wizard-tree-row {
  display: flex;
  align-content: center;
  align-items: center;
  background-color: $crb-white;
}

.drawer-row-content {
  display: inline-flex;
  flex: 1;
  align-content: center;
  align-items: center;
  padding-left: 20px;
}

.drawer-headers {
  font-weight: bold;
  display: flex;
  border-bottom: 1px solid $crb-grey-60;
}

.drawer-header {
  text-align: center;
}

.drawer-header-code-description {
  flex: 1.5;
  margin-right: 50px; // Align the headers accoringly to wizard-tree-row flexbox proportions
}

.drawer-header-comparison {
  flex: 3;
}

.drawer-header-quantity-amount {
  flex: 1;
}

.drawer-row-regulation-code {
  flex: 0 1 50px; // Limit the element width to 50px or less
}

.drawer-row-regulation {
  flex: 1;
  display: inline-flex;
}

.regulation-title-short-name {
  flex: 2 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.regulation-short-name {
  width: 40%;
  text-align: center;
  margin-right: 5px;
}

.regulation-title {
  flex: 2 2;
  display: flex;
  align-items: center;
}

.regulation-mapping {
  flex: 1 0;
  display: inline-flex;
  justify-content: flex-end;

  &--hidden {
    opacity: 0;
  }
}

.regulation-cost-data {
  position: relative;
  flex: 1 0 50%;
  display: inline-flex;
  flex-direction: column;

  .box-plot__median {
    height: 4.7em;
    transform: translate(0, -3.5em);
  }
}

.regulation-value {
  flex: 1;
  display: inline-flex;

  &__item {
    width: 20%;
    text-align: end;
    margin-right: 5px;
    height: 3em;
  }

  &__mappings {
    width: 100%;
    height: 3em;
    margin-right: 5px;

    & .cost-value__value {
      font-size: 14px;
    }
  }

  &__item-min {
    width: min-content;
    text-align: end;
    margin-right: 5px;
  }

  &__slider {
    width: 60%;
  }
}

.drawer-row-action-buttons {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0.1;
}

.drawer-row-action-button {
  justify-content: space-between;
  display: flex;
  width: 60px;
  text-align: end;
  cursor: pointer;

  &__icon {
    border: solid 1px $crb-black;
    color: $crb-black;
    padding: 2px;

    &--locked {
      background-color: $crb-states-locked;
    }
  }
}

.drawer-child {
  margin-left: 50px;
}

.drawer-icon {
  width: 20px; // Fix as some icons don't have the same width
  height: 20px; // Fix as some icons don't have the same width
  align-self: center;
}

.drawer-collapser {
  margin-left: 10px;
}

.regulation-state {
  height: 100%;
  flex: 0;
  padding: 25px 2px;

  &--unknown {
    background-color: $crb-states-unknown;
  }

  &--okay {
    background-color: $crb-states-okay;
  }

  &--warning {
    background-color: $crb-states-warning;
  }

  &--error {
    background-color: $crb-states-error;
  }
}

.cost-value {
  display: flex;
  flex-direction: column;
  background-color: $crb-grey-10;
  padding: 2px 5px;

  &__label {
    flex: 1;
    text-align: start;
    white-space: nowrap;
    font-size: small;
  }

  &__value {
    flex: 1;
    position: relative;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      line-height: 100%;
      text-align: right;
    }
  }

  &__input-field {
    line-height: 1;
    font-weight: inherit;
    text-align: right;
    padding: unset;
    bottom: -2px;
  }

  &--locked {
    background-color: $crb-states-locked;
    color: $crb-black;
  }
}

label.cost-value__label {
  display: block;
}
