.editor-floor__row-info {
  border-bottom: solid 1px $gray;
}

.editor-floor__floor-list {
  font-size: 20px;
}

.editor-floor__floor-list--multi {
  .editor-floor__row-info {
    border-top: solid 1px black;
    padding-top: 20px;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
}

.editor-floor__floor-list .form__input-field {
  padding-top: 3px;
}

.editor-floor__floor-list .form__input {
  margin-bottom: 0;
}

.editor-floor__row-function {
  padding-top: 6px;
  padding-bottom: 6px;
}

.editor-floor__row-add-function {
  padding-top: 6px;
  padding-bottom: 18px;
}

.editor-floor__move-floor {
  display: inline-flex;
  flex-direction: column;
  height: 1rem;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 12px;
}

.editor-floor__move-floor-up {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: solid 2px transparent;
    border-top-color: black;
    border-left-color: black;
    transform: rotate(45deg);
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }
  }
}

.editor-floor__move-floor-down {
  position: relative;
  vertical-align: sub;

  &::after {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border: solid 2px transparent;
    border-top-color: black;
    border-left-color: black;
    transform: rotate(225deg);
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }
  }
}

.editor-floor__action {
  color: $green;
  font-weight: bolder;
  cursor: pointer;
}
