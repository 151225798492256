#dialog-container .data-collection {
  .dialog__content {
    max-width: 90%;

    .dialog__body {
      max-height: calc(100vh - 300px);
      overflow-y: scroll;
      font-size: 18px;
    }
  }
}
