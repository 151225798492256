.navigation {
  display: block;

  > * + * {
    @extend .small-push-top;
  }
}

.navigation__title {
  padding: 20px 15px;

  @include media-breakpoint-up(md) {
    padding: 20px 30px;
  }

  &--hoverable {
    &:hover,
    &:focus,
    &:active {
      background-color: $green;
      outline: none !important;
    }
  }
}

.navigation__list {
  border-top: 2px solid $black;
}

.navigation__list-item {
  border-bottom: 2px solid $black;
}

.navigation__link {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.3s;

  @include media-breakpoint-up(md) {
    padding: 20px 30px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $green;
    outline: none !important;
  }
}

.navigation__link--active {
  font-weight: bold;
  background-color: $green;
}
