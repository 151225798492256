.project-share {
}

.project-share-overlay {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: rgba($green, 0.5);
  z-index: 10;

  &__close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .project-share__button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: grid;
    place-items: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
  }
}

.project-share-form {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid black;

  &__licences {
    overflow-y: auto;
    overflow-x: visible;
    flex-shrink: 1;
    flex-grow: 1;
  }

  &__entry {
    position: relative;
    align-items: center;

    &--create {
      .form__input {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }
    }

    .form__input {
      margin-bottom: 0;
    }

    &__check {
      color: green;
    }
    &__cross {
      color: red;
    }
    &__locked {
      color: grey;
    }
  }

  .project-share__button {
    right: 4rem;
  }
}
