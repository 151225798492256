@mixin disabled {
  cursor: not-allowed !important;
  color: $crb-grey-40;
}

.form__checkbox[data-disabled='true'] {
  @include disabled;
}

.checkbox {
  position: relative;
  height: 22px;

  input[type='checkbox'] {
    opacity: 0;
    min-width: 22px;
    min-height: 22px;

    &:disabled {
      cursor: default !important;

      & ~ .checkbox-checkmark {
        @include disabled;
      }
    }

    ~ .checkbox-checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: $gray;
      border: 1px solid $black;
      transform: translateY(-50%);

      &::after {
        content: '';
        position: absolute;
        box-sizing: content-box;
        display: none;
        left: 50%;
        top: 45%;
        width: 4px;
        height: 8px;
        border: solid $black;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:checked ~ .checkbox-checkmark {
      background-color: $green;

      &:after {
        display: block;
      }
    }
  }
}
