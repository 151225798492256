.project-editor-view {
  &.media-view {
    .media-list {
      [data-selected='true'] {
        background: $green;
      }

      .draggable-item:first-of-type {
        .media-item {
          background-color: #efeeee;
        }
      }

      .draggable-item {
        .media-item {
          padding-right: 48px;
        }
        .media-number {
          display: flex;
          align-items: center;
        }
        .media-preview-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }

    .upload-area {
      border: 2px solid #000;

      .image-upload__constraints {
        margin-top: 16px;
        line-height: 100%;
      }
    }

    .image-preview {
      position: relative;
      overflow: hidden;

      background: #333;

      &.light {
        background: #fff;

        .image-canvas {
          .image-canvas-error {
            color: #333;
          }
        }
      }

      .background-toggle {
        position: absolute;
        top: 8px;
        right: 8px;

        font-size: 24px;

        &[data-background='dark'] {
          color: #fff;
        }
      }

      .image-canvas {
        .image-canvas-error {
          color: #fff;
          font-weight: bold;
        }
      }

      // &::before {
      //     position: relative;
      //     display: block;
      //     padding-bottom: 75%;
      //     content: '';
      // }

      img {
        width: 100%;

        max-height: 100%;
        object-fit: contain;
      }

      .image-name {
        position: absolute;
        bottom: 8px;
        left: 8px;

        background: rgba(0, 0, 0, 0.75);
        color: #fff;
        font-weight: bold;

        padding: 2px 8px;
        border-radius: 4px;
      }
    }
  }
}

.upload-area__error {
  color: $red;
  margin-top: 16px;
}
