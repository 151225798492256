$font-primary: 'Proxima Nova W01';
$font-secondary: 'Apercu';

$light-green: #dfe7e3 !default;
$green: #c1d0c8 !default;
$black: #000 !default;
$white: #fff !default;
$gray: #efeeee !default;
$dark-gray: #656464 !default;
$red: #d79090 !default;
$yellow: #ebe0b9 !default;

$primary: $light-green;
$secondary: $dark-gray;

$colors: (
  'light-green': $light-green,
  'green': $green,
  'black': $black,
  'white': $white,
  'gray': $gray,
  'dark-gray': $dark-gray,
  'red': $red,
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
) !default;

// CRB COLORS
$crb-black: #000000;
$crb-black-light: #212529;
$crb-grey-10: #efeeee;
$crb-grey-40: #888888;
$crb-grey-60: #656464;
$crb-white: #ffffff;

$crb-green-60: #e1e7e3;
$crb-green-100: #c4d0c9;
$crb-dark-green-100: #728d7f;
$crb-dark-green-80: #91a59a;
$crb-red-100: #d79090;
$crb-yellow-100: #ebe0b9;

// CRB STATE COLORS
$crb-states-unknown: $crb-grey-10;
$crb-states-okay: $crb-green-60;
$crb-states-warning: $crb-yellow-100;
$crb-states-error: $crb-red-100;
$crb-states-success: #090;
$crb-states-error-bad: #900;
$crb-states-success: #090;
$crb-states-locked: $crb-green-100;

$crb-outline: 2px solid $crb-black;
