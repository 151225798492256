.status-indicator {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: solid 2px $black;
  margin-right: 20px;
  padding: 4px 0;
  cursor: pointer;
  min-width: 150px;

  &__label {
    display: inline-flex;
    align-items: center;
    font-size: 16px;

    &::before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      content: ' ';
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border: solid 2px black;
      margin-right: 0.5em;
      text-align: center;
      font-family: monospace;
      line-height: 1em;
      font-size: 12px;
    }
  }

  &--todo {
    border-top-color: $black;
  }

  &--in-progress {
    border-top-color: $green;
    font-weight: bold;

    .status-indicator__label::before {
      background-color: $white;
      border: solid 6px $green;
    }
  }

  &--ready {
    border-top-color: $green;
    font-weight: bold;

    .status-indicator__label::before {
      background-color: $green;
      border: solid 6px $green;
    }
  }

  &--done {
    border-top-color: $black;

    .status-indicator__label::before {
      color: white;
      background-color: $black;
      content: '✓';
    }
  }
}
