.header {
  padding-top: 15px;
  padding-bottom: 15px;

  @include media-breakpoint-up(md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.header__home-link {
  display: block;
  width: 150px;

  @include media-breakpoint-up(md) {
    width: 200px;
  }

  @include media-breakpoint-up(xl) {
    width: 230px;
  }
}

.header__navigation-toggle {
  display: inline-block;
  margin-left: 10px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  width: 24px;
  height: 8px;
  position: relative;
  outline: none !important;

  @include media-breakpoint-up(sm) {
    margin-left: 45px;
    width: 40px;
    height: 12px;
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: $black;
    position: absolute;
    left: 0;

    @include media-breakpoint-up(sm) {
      height: 3px;
    }
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:focus {
    outline: none !important;
  }
}
