.estimation-plausibility {
  .categories-tableau-container {
    position: relative;
  }

  .category-row {
    display: flex;

    .category-content {
      width: 100%;
    }

    .category-description {
      display: none;
    }
  }

  .tableau-wrapper {
    position: absolute;
    top: -9999px;
    left: -9999px;
    width: 100%;
  }
}

.estimation-plausibility--with-description {
  .category-row {
    .category-content {
      width: 70%;
    }

    .category-description {
      display: block;
      width: 30%;
    }
  }
}

.estimation-plausibility--with-tableau {
  .tableau-wrapper {
    position: static;
  }

  .categories-wrapper {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.icon-buttons {
  display: flex;
  justify-content: flex-end;

  .button-group {
    margin-right: 100px;

    button:nth-of-type(1) {
      border-right: 0;
    }
  }

  .menu-button + .menu-button {
    margin-left: 100px;
  }
}

.tableau-select-reference-projects {
  /*
    Need to algin this element with tableau iframe. 
    iframe content has a fixed width of 1200px, in larger screen it will create horizontal padding 
  */
  padding-left: calc((100% - 1200px) / 2);

  &--error {
    color: $crb-states-error-bad;
  }
}

.tableau-navigation {
  flex: 1;
  padding-top: calc(1rem + 40px); // align with tableau iframe

  .button {
    padding: 10px;
  }

  &__button--active {
    background-color: $green;
  }
}

.category-checkbox__label {
  display: flex;

  &--code {
    min-width: 20px;
  }
  &--description {
    font-size: smaller;
  }
}

.category-description__with-text-area {
  padding: 1rem 1rem 1rem 0;
  background-color: $white;

  .form__input {
    height: 100%;
    font-size: smaller;
  }

  .form__input-field {
    height: 100%;
  }
}
