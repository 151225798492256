.cart {
  margin-top: 15px;
  width: 100%; // Fixed by ti&m - TODO: validate change

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

.cart__row td {
  padding-top: 15px;

  @include media-breakpoint-up(md) {
    padding-top: 30px;
  }
}

.cart__row:first-of-type td {
  padding-top: 0;
}

.cart__row--bold td {
  font-weight: bold;
}

.cart__label {
  width: 60%;
  padding-right: 15px;

  @include media-breakpoint-up(md) {
    padding-right: 30px;
  }
}

.cart__value {
  text-align: right;
}
