@import url(https://fast.fonts.net/cssapi/6e28d51e-229f-40da-a379-40f451dc9392.css);
@charset "UTF-8";
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d79090;
  --orange: #fd7e14;
  --yellow: #ebe0b9;
  --green: #c1d0c8;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #efeeee;
  --gray-dark: #343a40;
  --light-green: #dfe7e3;
  --black: #000;
  --dark-gray: #656464;
  --primary: #dfe7e3;
  --secondary: #656464;
  --success: #c1d0c8;
  --info: #17a2b8;
  --warning: #ebe0b9;
  --danger: #d79090;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #dfe7e3;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #b3c6bd;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}

.container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f6f8f7;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #eef3f0;
}

.table-hover .table-primary:hover {
  background-color: #e8edea;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e8edea;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d4d4;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #afaeae;
}

.table-hover .table-secondary:hover {
  background-color: #c7c7c7;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c7c7c7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #eef2f0;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #dfe7e2;
}

.table-hover .table-success:hover {
  background-color: #e0e7e3;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #e0e7e3;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9f6eb;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5efdb;
}

.table-hover .table-warning:hover {
  background-color: #f3edd7;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f3edd7;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4e0e0;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eac5c5;
}

.table-hover .table-danger:hover {
  background-color: #edcdcd;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #edcdcd;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: white;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
          appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c1d0c8;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(193, 208, 200, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #c1d0c8;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23c1d0c8' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #c1d0c8;
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #c1d0c8;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23c1d0c8' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #c1d0c8;
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #c1d0c8;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #c1d0c8;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #c1d0c8;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #dee6e2;
  background-color: #dee6e2;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c1d0c8;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #c1d0c8;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #c1d0c8;
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d79090;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(215, 144, 144, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d79090;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d79090' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d79090' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d79090;
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d79090;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d79090' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d79090' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d79090;
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d79090;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d79090;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d79090;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e5b5b5;
  background-color: #e5b5b5;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d79090;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d79090;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d79090;
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.btn-primary:hover {
  color: #212529;
  background-color: #c9d7d0;
  border-color: #c2d1ca;
}
.btn-primary:focus, .btn-primary.focus {
  color: #212529;
  background-color: #c9d7d0;
  border-color: #c2d1ca;
  box-shadow: 0 0 0 0.2rem rgba(195, 202, 199, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #c2d1ca;
  border-color: #bbccc3;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 202, 199, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #656464;
  border-color: #656464;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #525151;
  border-color: #4b4b4b;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #525151;
  border-color: #4b4b4b;
  box-shadow: 0 0 0 0.2rem rgba(124, 123, 123, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #656464;
  border-color: #656464;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #454444;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 123, 123, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #c1d0c8;
  border-color: #c1d0c8;
}
.btn-success:hover {
  color: #212529;
  background-color: #abc0b5;
  border-color: #a4baae;
}
.btn-success:focus, .btn-success.focus {
  color: #212529;
  background-color: #abc0b5;
  border-color: #a4baae;
  box-shadow: 0 0 0 0.2rem rgba(169, 182, 176, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #c1d0c8;
  border-color: #c1d0c8;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #212529;
  background-color: #a4baae;
  border-color: #9db5a8;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 182, 176, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ebe0b9;
  border-color: #ebe0b9;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e3d39b;
  border-color: #e0ce91;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e3d39b;
  border-color: #e0ce91;
  box-shadow: 0 0 0 0.2rem rgba(205, 196, 163, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ebe0b9;
  border-color: #ebe0b9;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e0ce91;
  border-color: #ddca87;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 196, 163, 0.5);
}

.btn-danger {
  color: #212529;
  background-color: #d79090;
  border-color: #d79090;
}
.btn-danger:hover {
  color: #fff;
  background-color: #cd7474;
  border-color: #c96b6b;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #cd7474;
  border-color: #c96b6b;
  box-shadow: 0 0 0 0.2rem rgba(188, 128, 129, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #212529;
  background-color: #d79090;
  border-color: #d79090;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c96b6b;
  border-color: #c66161;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 128, 129, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #dfe7e3;
  border-color: #dfe7e3;
}
.btn-outline-primary:hover {
  color: #212529;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #dfe7e3;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.5);
}

.btn-outline-secondary {
  color: #656464;
  border-color: #656464;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #656464;
  border-color: #656464;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 100, 100, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #656464;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #656464;
  border-color: #656464;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 100, 100, 0.5);
}

.btn-outline-success {
  color: #c1d0c8;
  border-color: #c1d0c8;
}
.btn-outline-success:hover {
  color: #212529;
  background-color: #c1d0c8;
  border-color: #c1d0c8;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #c1d0c8;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #c1d0c8;
  border-color: #c1d0c8;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ebe0b9;
  border-color: #ebe0b9;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ebe0b9;
  border-color: #ebe0b9;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 224, 185, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ebe0b9;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ebe0b9;
  border-color: #ebe0b9;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 224, 185, 0.5);
}

.btn-outline-danger {
  color: #d79090;
  border-color: #d79090;
}
.btn-outline-danger:hover {
  color: #212529;
  background-color: #d79090;
  border-color: #d79090;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d79090;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #d79090;
  border-color: #d79090;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #dfe7e3;
  text-decoration: none;
}
.btn-link:hover {
  color: #b3c6bd;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #dfe7e3;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #dfe7e3;
  background-color: #dfe7e3;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: white;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #dfe7e3;
  background-color: #dfe7e3;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(223, 231, 227, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(223, 231, 227, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(223, 231, 227, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(223, 231, 227, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: white;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: white;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #dfe7e3;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #dfe7e3;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: white;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #dfe7e3;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: white;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #dfe7e3;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #dfe7e3;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #b3c6bd;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #212529;
  background-color: #dfe7e3;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #212529;
  background-color: #c2d1ca;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 231, 227, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #656464;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #4b4b4b;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(101, 100, 100, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #c1d0c8;
}
a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #a4baae;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(193, 208, 200, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ebe0b9;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #e0ce91;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 224, 185, 0.5);
}

.badge-danger {
  color: #212529;
  background-color: #d79090;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #212529;
  background-color: #c96b6b;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(215, 144, 144, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #747876;
  background-color: #f9faf9;
  border-color: #f6f8f7;
}
.alert-primary hr {
  border-top-color: #e8edea;
}
.alert-primary .alert-link {
  color: #5b5e5d;
}

.alert-secondary {
  color: #353434;
  background-color: #e0e0e0;
  border-color: #d4d4d4;
}
.alert-secondary hr {
  border-top-color: #c7c7c7;
}
.alert-secondary .alert-link {
  color: #1b1b1b;
}

.alert-success {
  color: #646c68;
  background-color: #f3f6f4;
  border-color: #eef2f0;
}
.alert-success hr {
  border-top-color: #e0e7e3;
}
.alert-success .alert-link {
  color: #4b524f;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #7a7460;
  background-color: #fbf9f1;
  border-color: #f9f6eb;
}
.alert-warning hr {
  border-top-color: #f3edd7;
}
.alert-warning .alert-link {
  color: #5d594a;
}

.alert-danger {
  color: #704b4b;
  background-color: #f7e9e9;
  border-color: #f4e0e0;
}
.alert-danger hr {
  border-top-color: #edcdcd;
}
.alert-danger .alert-link {
  color: #513737;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #dfe7e3;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #dfe7e3;
  border-color: #dfe7e3;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #747876;
  background-color: #f6f8f7;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #747876;
  background-color: #e8edea;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #747876;
  border-color: #747876;
}

.list-group-item-secondary {
  color: #353434;
  background-color: #d4d4d4;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #353434;
  background-color: #c7c7c7;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #353434;
  border-color: #353434;
}

.list-group-item-success {
  color: #646c68;
  background-color: #eef2f0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #646c68;
  background-color: #e0e7e3;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #646c68;
  border-color: #646c68;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #7a7460;
  background-color: #f9f6eb;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7a7460;
  background-color: #f3edd7;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7a7460;
  border-color: #7a7460;
}

.list-group-item-danger {
  color: #704b4b;
  background-color: #f4e0e0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #704b4b;
  background-color: #edcdcd;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #704b4b;
  border-color: #704b4b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #dfe7e3 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #c2d1ca !important;
}

.bg-secondary {
  background-color: #656464 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4b4b4b !important;
}

.bg-success {
  background-color: #c1d0c8 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #a4baae !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ebe0b9 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e0ce91 !important;
}

.bg-danger {
  background-color: #d79090 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c96b6b !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #dfe7e3 !important;
}

.border-secondary {
  border-color: #656464 !important;
}

.border-success {
  border-color: #c1d0c8 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ebe0b9 !important;
}

.border-danger {
  border-color: #d79090 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #dfe7e3 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #b3c6bd !important;
}

.text-secondary {
  color: #656464 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #3f3e3e !important;
}

.text-success {
  color: #c1d0c8 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #95afa1 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ebe0b9 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #dac67e !important;
}

.text-danger {
  color: #d79090 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c35858 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "Apercu";
  src: local("Apercu Regular"), local("ApercuRegular"), url(/static/media/apercu_regular.59469dee.woff2) format("woff2"), url(/static/media/apercu_regular.b23291da.woff) format("woff"), url(/static/media/apercu_regular.7b407a35.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Apercu";
  src: local("Apercu Medium"), local("ApercuMedium"), url(/static/media/apercu_medium.29957710.woff2) format("woff2"), url(/static/media/apercu_medium.f6ed8d62.woff) format("woff"), url(/static/media/apercu_medium.c0c2b3bb.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  color: black;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  font-family: "Proxima Nova W01";
  font-weight: 300;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
@media (min-width: 768px) {
  html,
body {
    font-size: 17px;
    line-height: 25px;
  }
}
@media (min-width: 1200px) {
  html,
body {
    font-size: 18px;
    line-height: 26px;
  }
}

html {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (min-width: 576px) {
  html {
    width: 100vw;
  }
}

body {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.wrapper main {
  flex-grow: 1;
}

h1 {
  font-family: "Apercu";
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
}
@media (min-width: 768px) {
  h1 {
    font-size: 30px;
    line-height: 33px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 32px;
    line-height: 36px;
  }
}

h2 {
  font-family: "Apercu";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 24px;
    line-height: 29px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

.lead,
h3 {
  font-family: "Apercu";
  font-size: 18px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .lead,
h3 {
    font-size: 19px;
    line-height: 21px;
  }
}
@media (min-width: 1200px) {
  .lead,
h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

h4 {
  font-family: "Apercu";
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 768px) {
  h4 {
    font-size: 17px;
    line-height: 19px;
  }
}
@media (min-width: 1200px) {
  h4 {
    font-size: 18px;
    line-height: 22px;
  }
}

a {
  color: #000;
  text-decoration: underline;
}
a:hover, a:focus, a:active {
  color: #000;
  text-decoration: none;
}

hr {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  background-color: black;
}

::selection {
  color: #000;
  background-color: #c1d0c8;
}

.img--hoverable {
  transition: box-shadow 200ms linear;
  box-shadow: 0 0 0 transparent;
  cursor: pointer;
}
.img--hoverable:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.33);
}

.inset-0 {
  inset: 0;
}

.bg-blue {
  background-color: #007bff;
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-pink {
  background-color: #e83e8c;
}

.bg-red {
  background-color: #d79090;
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-yellow {
  background-color: #ebe0b9;
}

.bg-green {
  background-color: #c1d0c8;
}

.bg-teal {
  background-color: #20c997;
}

.bg-cyan {
  background-color: #17a2b8;
}

.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #efeeee;
}

.bg-gray-dark {
  background-color: #343a40;
}

.bg-light-green {
  background-color: #dfe7e3;
}

.bg-black {
  background-color: #000;
}

.bg-dark-gray {
  background-color: #656464;
}

@media (min-width: 0) {
  .position-xs-absolute {
    position: absolute;
  }

  .position-xs-relative {
    position: relative;
  }
}
@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute;
  }

  .position-sm-relative {
    position: relative;
  }
}
@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute;
  }

  .position-md-relative {
    position: relative;
  }
}
@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute;
  }

  .position-lg-relative {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute;
  }

  .position-xl-relative {
    position: relative;
  }
}
@media (min-width: 1600px) {
  .position-xxl-absolute {
    position: absolute;
  }

  .position-xxl-relative {
    position: relative;
  }
}
.responsive {
  display: block;
  width: 100%;
}

.no-scroll {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-btn-unstyled {
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;
  margin: 0;
}

.hoverable:hover, .hoverable:focus, .hoverable:active {
  background-color: #efeeee;
  outline: none !important;
}

[data-hover=true] {
  background-color: #efeeee;
  outline: none !important;
}

h1 + p {
  margin-top: 15px;
}
@media (min-width: 768px) {
  h1 + p {
    margin-top: 25px;
  }
}

h2 + p,
h3 + p {
  margin-top: 10px;
}
@media (min-width: 768px) {
  h2 + p,
h3 + p {
    margin-top: 15px;
  }
}

@media (max-width: 575.98px) {
  .xs-push-top {
    margin-top: 15px;
  }
}

@media (max-width: 575.98px) {
  .xs-big-push-top {
    margin-top: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sm-push-top {
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .md-push-top {
    margin-top: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .lg-push-top {
    margin-top: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
  .xl-push-top {
    margin-top: 30px;
  }
}

.small-gutter-top {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .small-gutter-top {
    margin-top: 20px;
  }
}

.gutter-top {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .gutter-top {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .gutter-top {
    margin-top: 30px;
  }
}

.medium-gutter-top {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .medium-gutter-top {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .medium-gutter-top {
    margin-top: 40px;
  }
}

.small-push-top, .navigation > * + * {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .small-push-top, .navigation > * + * {
    margin-top: 50px;
  }
}
@media (min-width: 1200px) {
  .small-push-top, .navigation > * + * {
    margin-top: 75px;
  }
}

.small-push-bottom {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .small-push-bottom {
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .small-push-bottom {
    margin-bottom: 75px;
  }
}

.very-small-push-top {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .very-small-push-top {
    margin-top: 1rem;
  }
}

.very-small-push-bottom {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .very-small-push-bottom {
    margin-bottom: 1rem;
  }
}

.small-padding-top {
  padding-top: 35px;
}
@media (min-width: 768px) {
  .small-padding-top {
    padding-top: 50px;
  }
}
@media (min-width: 1200px) {
  .small-padding-top {
    padding-top: 75px;
  }
}

.small-padding-bottom {
  padding-bottom: 35px;
}
@media (min-width: 768px) {
  .small-padding-bottom {
    padding-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .small-padding-bottom {
    padding-bottom: 75px;
  }
}

.push-top {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .push-top {
    margin-top: 75px;
  }
}
@media (min-width: 1200px) {
  .push-top {
    margin-top: 150px;
  }
}

.push-bottom {
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .push-bottom {
    margin-bottom: 75px;
  }
}
@media (min-width: 1200px) {
  .push-bottom {
    margin-bottom: 150px;
  }
}

.col-24-1 {
  max-width: 4.1666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-1 {
    max-width: 4.1666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-1 {
    max-width: 4.1666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-1 {
    max-width: 4.1666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-1 {
    max-width: 4.1666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-1 {
    max-width: 4.1666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-1 {
    max-width: 4.1666666667% !important;
  }
}
.col-24-2 {
  max-width: 8.3333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-2 {
    max-width: 8.3333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-2 {
    max-width: 8.3333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-2 {
    max-width: 8.3333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-2 {
    max-width: 8.3333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-2 {
    max-width: 8.3333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-2 {
    max-width: 8.3333333333% !important;
  }
}
.col-24-3 {
  max-width: 12.5% !important;
}

@media (min-width: 0) {
  .col-xs-24-3 {
    max-width: 12.5% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-3 {
    max-width: 12.5% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-3 {
    max-width: 12.5% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-3 {
    max-width: 12.5% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-3 {
    max-width: 12.5% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-3 {
    max-width: 12.5% !important;
  }
}
.col-24-4 {
  max-width: 16.6666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-4 {
    max-width: 16.6666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-4 {
    max-width: 16.6666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-4 {
    max-width: 16.6666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-4 {
    max-width: 16.6666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-4 {
    max-width: 16.6666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-4 {
    max-width: 16.6666666667% !important;
  }
}
.col-24-5 {
  max-width: 20.8333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-5 {
    max-width: 20.8333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-5 {
    max-width: 20.8333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-5 {
    max-width: 20.8333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-5 {
    max-width: 20.8333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-5 {
    max-width: 20.8333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-5 {
    max-width: 20.8333333333% !important;
  }
}
.col-24-6 {
  max-width: 25% !important;
}

@media (min-width: 0) {
  .col-xs-24-6 {
    max-width: 25% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-6 {
    max-width: 25% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-6 {
    max-width: 25% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-6 {
    max-width: 25% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-6 {
    max-width: 25% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-6 {
    max-width: 25% !important;
  }
}
.col-24-7 {
  max-width: 29.1666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-7 {
    max-width: 29.1666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-7 {
    max-width: 29.1666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-7 {
    max-width: 29.1666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-7 {
    max-width: 29.1666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-7 {
    max-width: 29.1666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-7 {
    max-width: 29.1666666667% !important;
  }
}
.col-24-8 {
  max-width: 33.3333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-8 {
    max-width: 33.3333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-8 {
    max-width: 33.3333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-8 {
    max-width: 33.3333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-8 {
    max-width: 33.3333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-8 {
    max-width: 33.3333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-8 {
    max-width: 33.3333333333% !important;
  }
}
.col-24-9 {
  max-width: 37.5% !important;
}

@media (min-width: 0) {
  .col-xs-24-9 {
    max-width: 37.5% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-9 {
    max-width: 37.5% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-9 {
    max-width: 37.5% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-9 {
    max-width: 37.5% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-9 {
    max-width: 37.5% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-9 {
    max-width: 37.5% !important;
  }
}
.col-24-10 {
  max-width: 41.6666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-10 {
    max-width: 41.6666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-10 {
    max-width: 41.6666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-10 {
    max-width: 41.6666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-10 {
    max-width: 41.6666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-10 {
    max-width: 41.6666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-10 {
    max-width: 41.6666666667% !important;
  }
}
.col-24-11 {
  max-width: 45.8333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-11 {
    max-width: 45.8333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-11 {
    max-width: 45.8333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-11 {
    max-width: 45.8333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-11 {
    max-width: 45.8333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-11 {
    max-width: 45.8333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-11 {
    max-width: 45.8333333333% !important;
  }
}
.col-24-12 {
  max-width: 50% !important;
}

@media (min-width: 0) {
  .col-xs-24-12 {
    max-width: 50% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-12 {
    max-width: 50% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-12 {
    max-width: 50% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-12 {
    max-width: 50% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-12 {
    max-width: 50% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-12 {
    max-width: 50% !important;
  }
}
.col-24-13 {
  max-width: 54.1666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-13 {
    max-width: 54.1666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-13 {
    max-width: 54.1666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-13 {
    max-width: 54.1666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-13 {
    max-width: 54.1666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-13 {
    max-width: 54.1666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-13 {
    max-width: 54.1666666667% !important;
  }
}
.col-24-14 {
  max-width: 58.3333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-14 {
    max-width: 58.3333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-14 {
    max-width: 58.3333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-14 {
    max-width: 58.3333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-14 {
    max-width: 58.3333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-14 {
    max-width: 58.3333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-14 {
    max-width: 58.3333333333% !important;
  }
}
.col-24-15 {
  max-width: 62.5% !important;
}

@media (min-width: 0) {
  .col-xs-24-15 {
    max-width: 62.5% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-15 {
    max-width: 62.5% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-15 {
    max-width: 62.5% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-15 {
    max-width: 62.5% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-15 {
    max-width: 62.5% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-15 {
    max-width: 62.5% !important;
  }
}
.col-24-16 {
  max-width: 66.6666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-16 {
    max-width: 66.6666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-16 {
    max-width: 66.6666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-16 {
    max-width: 66.6666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-16 {
    max-width: 66.6666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-16 {
    max-width: 66.6666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-16 {
    max-width: 66.6666666667% !important;
  }
}
.col-24-17 {
  max-width: 70.8333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-17 {
    max-width: 70.8333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-17 {
    max-width: 70.8333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-17 {
    max-width: 70.8333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-17 {
    max-width: 70.8333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-17 {
    max-width: 70.8333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-17 {
    max-width: 70.8333333333% !important;
  }
}
.col-24-18 {
  max-width: 75% !important;
}

@media (min-width: 0) {
  .col-xs-24-18 {
    max-width: 75% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-18 {
    max-width: 75% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-18 {
    max-width: 75% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-18 {
    max-width: 75% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-18 {
    max-width: 75% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-18 {
    max-width: 75% !important;
  }
}
.col-24-19 {
  max-width: 79.1666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-19 {
    max-width: 79.1666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-19 {
    max-width: 79.1666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-19 {
    max-width: 79.1666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-19 {
    max-width: 79.1666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-19 {
    max-width: 79.1666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-19 {
    max-width: 79.1666666667% !important;
  }
}
.col-24-20 {
  max-width: 83.3333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-20 {
    max-width: 83.3333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-20 {
    max-width: 83.3333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-20 {
    max-width: 83.3333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-20 {
    max-width: 83.3333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-20 {
    max-width: 83.3333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-20 {
    max-width: 83.3333333333% !important;
  }
}
.col-24-21 {
  max-width: 87.5% !important;
}

@media (min-width: 0) {
  .col-xs-24-21 {
    max-width: 87.5% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-21 {
    max-width: 87.5% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-21 {
    max-width: 87.5% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-21 {
    max-width: 87.5% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-21 {
    max-width: 87.5% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-21 {
    max-width: 87.5% !important;
  }
}
.col-24-22 {
  max-width: 91.6666666667% !important;
}

@media (min-width: 0) {
  .col-xs-24-22 {
    max-width: 91.6666666667% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-22 {
    max-width: 91.6666666667% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-22 {
    max-width: 91.6666666667% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-22 {
    max-width: 91.6666666667% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-22 {
    max-width: 91.6666666667% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-22 {
    max-width: 91.6666666667% !important;
  }
}
.col-24-23 {
  max-width: 95.8333333333% !important;
}

@media (min-width: 0) {
  .col-xs-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 576px) {
  .col-sm-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 768px) {
  .col-md-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 992px) {
  .col-lg-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 1200px) {
  .col-xl-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 1600px) {
  .col-xxl-24-23 {
    max-width: 95.8333333333% !important;
  }
}
@media (min-width: 0) {
  .h-xs-25 {
    height: 25%;
  }

  .w-xs-25 {
    width: 25%;
  }

  .mh-xs-25 {
    max-height: 25%;
  }

  .mw-xs-25 {
    width: 25%;
  }

  .vh-xs-25 {
    height: 25vh;
  }

  .vw-xs-25 {
    width: 25vw;
  }

  .mvh-xs-25 {
    max-height: 25vh;
  }

  .mvw-xs-25 {
    max-width: 25vw;
  }
}
@media (min-width: 0) {
  .h-xs-50 {
    height: 50%;
  }

  .w-xs-50 {
    width: 50%;
  }

  .mh-xs-50 {
    max-height: 50%;
  }

  .mw-xs-50 {
    width: 50%;
  }

  .vh-xs-50 {
    height: 50vh;
  }

  .vw-xs-50 {
    width: 50vw;
  }

  .mvh-xs-50 {
    max-height: 50vh;
  }

  .mvw-xs-50 {
    max-width: 50vw;
  }
}
@media (min-width: 0) {
  .h-xs-75 {
    height: 75%;
  }

  .w-xs-75 {
    width: 75%;
  }

  .mh-xs-75 {
    max-height: 75%;
  }

  .mw-xs-75 {
    width: 75%;
  }

  .vh-xs-75 {
    height: 75vh;
  }

  .vw-xs-75 {
    width: 75vw;
  }

  .mvh-xs-75 {
    max-height: 75vh;
  }

  .mvw-xs-75 {
    max-width: 75vw;
  }
}
@media (min-width: 0) {
  .h-xs-100 {
    height: 100%;
  }

  .w-xs-100 {
    width: 100%;
  }

  .mh-xs-100 {
    max-height: 100%;
  }

  .mw-xs-100 {
    width: 100%;
  }

  .vh-xs-100 {
    height: 100vh;
  }

  .vw-xs-100 {
    width: 100vw;
  }

  .mvh-xs-100 {
    max-height: 100vh;
  }

  .mvw-xs-100 {
    max-width: 100vw;
  }
}
@media (min-width: 0) {
  .h-xs-auto {
    height: auto;
  }

  .w-xs-auto {
    width: auto;
  }

  .mh-xs-auto {
    max-height: auto;
  }

  .mw-xs-auto {
    width: auto;
  }

  .vh-xs-auto {
    height: unset;
  }

  .vw-xs-auto {
    height: unset;
  }

  .mvh-xs-auto {
    max-height: unset;
  }

  .mvw-xs-auto {
    height: unset;
  }
}
@media (min-width: 576px) {
  .h-sm-25 {
    height: 25%;
  }

  .w-sm-25 {
    width: 25%;
  }

  .mh-sm-25 {
    max-height: 25%;
  }

  .mw-sm-25 {
    width: 25%;
  }

  .vh-sm-25 {
    height: 25vh;
  }

  .vw-sm-25 {
    width: 25vw;
  }

  .mvh-sm-25 {
    max-height: 25vh;
  }

  .mvw-sm-25 {
    max-width: 25vw;
  }
}
@media (min-width: 576px) {
  .h-sm-50 {
    height: 50%;
  }

  .w-sm-50 {
    width: 50%;
  }

  .mh-sm-50 {
    max-height: 50%;
  }

  .mw-sm-50 {
    width: 50%;
  }

  .vh-sm-50 {
    height: 50vh;
  }

  .vw-sm-50 {
    width: 50vw;
  }

  .mvh-sm-50 {
    max-height: 50vh;
  }

  .mvw-sm-50 {
    max-width: 50vw;
  }
}
@media (min-width: 576px) {
  .h-sm-75 {
    height: 75%;
  }

  .w-sm-75 {
    width: 75%;
  }

  .mh-sm-75 {
    max-height: 75%;
  }

  .mw-sm-75 {
    width: 75%;
  }

  .vh-sm-75 {
    height: 75vh;
  }

  .vw-sm-75 {
    width: 75vw;
  }

  .mvh-sm-75 {
    max-height: 75vh;
  }

  .mvw-sm-75 {
    max-width: 75vw;
  }
}
@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%;
  }

  .w-sm-100 {
    width: 100%;
  }

  .mh-sm-100 {
    max-height: 100%;
  }

  .mw-sm-100 {
    width: 100%;
  }

  .vh-sm-100 {
    height: 100vh;
  }

  .vw-sm-100 {
    width: 100vw;
  }

  .mvh-sm-100 {
    max-height: 100vh;
  }

  .mvw-sm-100 {
    max-width: 100vw;
  }
}
@media (min-width: 576px) {
  .h-sm-auto {
    height: auto;
  }

  .w-sm-auto {
    width: auto;
  }

  .mh-sm-auto {
    max-height: auto;
  }

  .mw-sm-auto {
    width: auto;
  }

  .vh-sm-auto {
    height: unset;
  }

  .vw-sm-auto {
    height: unset;
  }

  .mvh-sm-auto {
    max-height: unset;
  }

  .mvw-sm-auto {
    height: unset;
  }
}
@media (min-width: 768px) {
  .h-md-25 {
    height: 25%;
  }

  .w-md-25 {
    width: 25%;
  }

  .mh-md-25 {
    max-height: 25%;
  }

  .mw-md-25 {
    width: 25%;
  }

  .vh-md-25 {
    height: 25vh;
  }

  .vw-md-25 {
    width: 25vw;
  }

  .mvh-md-25 {
    max-height: 25vh;
  }

  .mvw-md-25 {
    max-width: 25vw;
  }
}
@media (min-width: 768px) {
  .h-md-50 {
    height: 50%;
  }

  .w-md-50 {
    width: 50%;
  }

  .mh-md-50 {
    max-height: 50%;
  }

  .mw-md-50 {
    width: 50%;
  }

  .vh-md-50 {
    height: 50vh;
  }

  .vw-md-50 {
    width: 50vw;
  }

  .mvh-md-50 {
    max-height: 50vh;
  }

  .mvw-md-50 {
    max-width: 50vw;
  }
}
@media (min-width: 768px) {
  .h-md-75 {
    height: 75%;
  }

  .w-md-75 {
    width: 75%;
  }

  .mh-md-75 {
    max-height: 75%;
  }

  .mw-md-75 {
    width: 75%;
  }

  .vh-md-75 {
    height: 75vh;
  }

  .vw-md-75 {
    width: 75vw;
  }

  .mvh-md-75 {
    max-height: 75vh;
  }

  .mvw-md-75 {
    max-width: 75vw;
  }
}
@media (min-width: 768px) {
  .h-md-100 {
    height: 100%;
  }

  .w-md-100 {
    width: 100%;
  }

  .mh-md-100 {
    max-height: 100%;
  }

  .mw-md-100 {
    width: 100%;
  }

  .vh-md-100 {
    height: 100vh;
  }

  .vw-md-100 {
    width: 100vw;
  }

  .mvh-md-100 {
    max-height: 100vh;
  }

  .mvw-md-100 {
    max-width: 100vw;
  }
}
@media (min-width: 768px) {
  .h-md-auto {
    height: auto;
  }

  .w-md-auto {
    width: auto;
  }

  .mh-md-auto {
    max-height: auto;
  }

  .mw-md-auto {
    width: auto;
  }

  .vh-md-auto {
    height: unset;
  }

  .vw-md-auto {
    height: unset;
  }

  .mvh-md-auto {
    max-height: unset;
  }

  .mvw-md-auto {
    height: unset;
  }
}
@media (min-width: 992px) {
  .h-lg-25 {
    height: 25%;
  }

  .w-lg-25 {
    width: 25%;
  }

  .mh-lg-25 {
    max-height: 25%;
  }

  .mw-lg-25 {
    width: 25%;
  }

  .vh-lg-25 {
    height: 25vh;
  }

  .vw-lg-25 {
    width: 25vw;
  }

  .mvh-lg-25 {
    max-height: 25vh;
  }

  .mvw-lg-25 {
    max-width: 25vw;
  }
}
@media (min-width: 992px) {
  .h-lg-50 {
    height: 50%;
  }

  .w-lg-50 {
    width: 50%;
  }

  .mh-lg-50 {
    max-height: 50%;
  }

  .mw-lg-50 {
    width: 50%;
  }

  .vh-lg-50 {
    height: 50vh;
  }

  .vw-lg-50 {
    width: 50vw;
  }

  .mvh-lg-50 {
    max-height: 50vh;
  }

  .mvw-lg-50 {
    max-width: 50vw;
  }
}
@media (min-width: 992px) {
  .h-lg-75 {
    height: 75%;
  }

  .w-lg-75 {
    width: 75%;
  }

  .mh-lg-75 {
    max-height: 75%;
  }

  .mw-lg-75 {
    width: 75%;
  }

  .vh-lg-75 {
    height: 75vh;
  }

  .vw-lg-75 {
    width: 75vw;
  }

  .mvh-lg-75 {
    max-height: 75vh;
  }

  .mvw-lg-75 {
    max-width: 75vw;
  }
}
@media (min-width: 992px) {
  .h-lg-100 {
    height: 100%;
  }

  .w-lg-100 {
    width: 100%;
  }

  .mh-lg-100 {
    max-height: 100%;
  }

  .mw-lg-100 {
    width: 100%;
  }

  .vh-lg-100 {
    height: 100vh;
  }

  .vw-lg-100 {
    width: 100vw;
  }

  .mvh-lg-100 {
    max-height: 100vh;
  }

  .mvw-lg-100 {
    max-width: 100vw;
  }
}
@media (min-width: 992px) {
  .h-lg-auto {
    height: auto;
  }

  .w-lg-auto {
    width: auto;
  }

  .mh-lg-auto {
    max-height: auto;
  }

  .mw-lg-auto {
    width: auto;
  }

  .vh-lg-auto {
    height: unset;
  }

  .vw-lg-auto {
    height: unset;
  }

  .mvh-lg-auto {
    max-height: unset;
  }

  .mvw-lg-auto {
    height: unset;
  }
}
@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25%;
  }

  .w-xl-25 {
    width: 25%;
  }

  .mh-xl-25 {
    max-height: 25%;
  }

  .mw-xl-25 {
    width: 25%;
  }

  .vh-xl-25 {
    height: 25vh;
  }

  .vw-xl-25 {
    width: 25vw;
  }

  .mvh-xl-25 {
    max-height: 25vh;
  }

  .mvw-xl-25 {
    max-width: 25vw;
  }
}
@media (min-width: 1200px) {
  .h-xl-50 {
    height: 50%;
  }

  .w-xl-50 {
    width: 50%;
  }

  .mh-xl-50 {
    max-height: 50%;
  }

  .mw-xl-50 {
    width: 50%;
  }

  .vh-xl-50 {
    height: 50vh;
  }

  .vw-xl-50 {
    width: 50vw;
  }

  .mvh-xl-50 {
    max-height: 50vh;
  }

  .mvw-xl-50 {
    max-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .h-xl-75 {
    height: 75%;
  }

  .w-xl-75 {
    width: 75%;
  }

  .mh-xl-75 {
    max-height: 75%;
  }

  .mw-xl-75 {
    width: 75%;
  }

  .vh-xl-75 {
    height: 75vh;
  }

  .vw-xl-75 {
    width: 75vw;
  }

  .mvh-xl-75 {
    max-height: 75vh;
  }

  .mvw-xl-75 {
    max-width: 75vw;
  }
}
@media (min-width: 1200px) {
  .h-xl-100 {
    height: 100%;
  }

  .w-xl-100 {
    width: 100%;
  }

  .mh-xl-100 {
    max-height: 100%;
  }

  .mw-xl-100 {
    width: 100%;
  }

  .vh-xl-100 {
    height: 100vh;
  }

  .vw-xl-100 {
    width: 100vw;
  }

  .mvh-xl-100 {
    max-height: 100vh;
  }

  .mvw-xl-100 {
    max-width: 100vw;
  }
}
@media (min-width: 1200px) {
  .h-xl-auto {
    height: auto;
  }

  .w-xl-auto {
    width: auto;
  }

  .mh-xl-auto {
    max-height: auto;
  }

  .mw-xl-auto {
    width: auto;
  }

  .vh-xl-auto {
    height: unset;
  }

  .vw-xl-auto {
    height: unset;
  }

  .mvh-xl-auto {
    max-height: unset;
  }

  .mvw-xl-auto {
    height: unset;
  }
}
@media (min-width: 1600px) {
  .h-xxl-25 {
    height: 25%;
  }

  .w-xxl-25 {
    width: 25%;
  }

  .mh-xxl-25 {
    max-height: 25%;
  }

  .mw-xxl-25 {
    width: 25%;
  }

  .vh-xxl-25 {
    height: 25vh;
  }

  .vw-xxl-25 {
    width: 25vw;
  }

  .mvh-xxl-25 {
    max-height: 25vh;
  }

  .mvw-xxl-25 {
    max-width: 25vw;
  }
}
@media (min-width: 1600px) {
  .h-xxl-50 {
    height: 50%;
  }

  .w-xxl-50 {
    width: 50%;
  }

  .mh-xxl-50 {
    max-height: 50%;
  }

  .mw-xxl-50 {
    width: 50%;
  }

  .vh-xxl-50 {
    height: 50vh;
  }

  .vw-xxl-50 {
    width: 50vw;
  }

  .mvh-xxl-50 {
    max-height: 50vh;
  }

  .mvw-xxl-50 {
    max-width: 50vw;
  }
}
@media (min-width: 1600px) {
  .h-xxl-75 {
    height: 75%;
  }

  .w-xxl-75 {
    width: 75%;
  }

  .mh-xxl-75 {
    max-height: 75%;
  }

  .mw-xxl-75 {
    width: 75%;
  }

  .vh-xxl-75 {
    height: 75vh;
  }

  .vw-xxl-75 {
    width: 75vw;
  }

  .mvh-xxl-75 {
    max-height: 75vh;
  }

  .mvw-xxl-75 {
    max-width: 75vw;
  }
}
@media (min-width: 1600px) {
  .h-xxl-100 {
    height: 100%;
  }

  .w-xxl-100 {
    width: 100%;
  }

  .mh-xxl-100 {
    max-height: 100%;
  }

  .mw-xxl-100 {
    width: 100%;
  }

  .vh-xxl-100 {
    height: 100vh;
  }

  .vw-xxl-100 {
    width: 100vw;
  }

  .mvh-xxl-100 {
    max-height: 100vh;
  }

  .mvw-xxl-100 {
    max-width: 100vw;
  }
}
@media (min-width: 1600px) {
  .h-xxl-auto {
    height: auto;
  }

  .w-xxl-auto {
    width: auto;
  }

  .mh-xxl-auto {
    max-height: auto;
  }

  .mw-xxl-auto {
    width: auto;
  }

  .vh-xxl-auto {
    height: unset;
  }

  .vw-xxl-auto {
    height: unset;
  }

  .mvh-xxl-auto {
    max-height: unset;
  }

  .mvw-xxl-auto {
    height: unset;
  }
}
.vh-25 {
  height: 25vh;
}

.mvh-25 {
  max-height: 25vh;
}

.vh-50 {
  height: 50vh;
}

.mvh-50 {
  max-height: 50vh;
}

.vh-75 {
  height: 75vh;
}

.mvh-75 {
  max-height: 75vh;
}

.vh-100 {
  height: 100vh;
}

.mvh-100 {
  max-height: 100vh;
}

.vh-auto {
  height: unset;
}

.mvh-auto {
  max-height: unset;
}

.box {
  border: 5px solid #c1d0c8;
  padding: 15px;
}
@media (min-width: 768px) {
  .box {
    border-width: 10px;
    padding: 30px;
  }
}
@media (min-width: 1200px) {
  .box {
    padding: 50px;
  }
}

.box-plot {
  position: relative;
  background-color: #efeeee;
  height: 1em;
}

.box-plot * {
  transition: all 0.3s ease-out;
  transition-delay: 0.25s;
  opacity: 1;
}

@keyframes loading-animation {
  0% {
    background-color: #efeeee;
  }
  50% {
    background-color: #c3d0c8;
  }
  100% {
    background-color: #efeeee;
  }
}
.box-plot.box-plot--loading {
  animation-name: loading-animation;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
}

.box-plot.box-plot--loading * {
  opacity: 0;
}

.box-plot.box-plot--empty .box-plot__quintiles,
.box-plot.box-plot--empty .box-plot__median,
.box-plot.box-plot--empty .box-plot__label {
  display: none;
}

.box-plot__median {
  position: absolute;
  height: 110%;
  top: -5%;
  border-left: 1px dashed #656464;
}

.box-plot__quintiles {
  position: absolute;
  height: 1em;
}

.box-plot__quintiles--outer {
  background-color: #c3d0c8;
}

.box-plot__quintiles--middle {
  background-color: #91a59a;
}

.box-plot__quintiles--inner {
  background-color: #718d7f;
}

.box-plot__label {
  position: absolute;
  color: #656464;
  top: 100%;
  left: 50%;
  font-size: 12px;
  transform: translate(-40%, 0);
  white-space: nowrap;
  overflow: visible;
}

.box-plot__empty-label {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: #656464;
  line-height: 1.2em;
  vertical-align: middle;
}

.building-plan-importer {
  display: grid;
  grid-template: [row1start] "nav-actions" [row1-end] [row2-start] "error" [row2-end] [row3-start] "content" [row3-end] [row4-start] "instructions" [row4-end] [row5-start] "nav-state" [row5-end];
  grid-row-gap: 16px;
}

.building-plan-importer__nav-state {
  grid-area: nav-state;
}

.building-plan-importer__nav-actions {
  grid-area: nav-actions;
  display: flex;
  justify-content: center;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.building-plan-importer__instructions {
  grid-area: instructions;
}

.building-plan-importer__error {
  grid-area: error;
  color: #d79090;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0% 10%;
  text-align: center;
}
.building-plan-importer__error--floating {
  position: absolute;
  top: 0%;
  width: calc(100% - 32px);
  background: #ffffff;
  margin: 16px;
  padding: 16px 10%;
  z-index: 1000;
}

.building-plan-importer__content {
  grid-area: content;
  position: relative;
  background-color: #dfe7e3;
  display: flex;
  justify-content: center;
  height: 40vh;
  padding: 3rem 0;
}

.building-plan-importer__content input {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.building-plan-importer__img {
  width: 60vw;
  max-width: 90%;
}

.building-plan-importer__error {
  color: #d79090;
  margin-top: 16px;
  margin-bottom: 16px;
}

.ifc-viewer {
  padding: 0px;
}

.building-plan + canvas {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.building-plan-wrapper {
  position: relative;
  height: 600px;
  min-height: 1px;
}

.building-editor-plan__building-plan-wrapper {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 10%;
  max-height: 80vh;
  min-height: calc(300px + 3em + 40px);
}

.building-editor-form .model-creation-form,
.building-editor-form .contour-selection-form {
  flex-grow: 1;
}

.building-editor-form__actions {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.button,
.menu-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  font-size: 16px;
  line-height: 1;
  padding: 7.5px 15px;
  border: 2px solid #000;
  transition: all 0.3s;
  color: #000;
  text-decoration: none;
  outline: none !important;
}
@media (min-width: 768px) {
  .button,
.menu-button {
    padding: 10px 40px;
  }
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active,
.menu-button:not([disabled]):hover,
.menu-button:not([disabled]):focus,
.menu-button:not([disabled]):active {
  color: #000;
  background-color: #c1d0c8;
  text-decoration: none;
}
.button[disabled],
.menu-button[disabled] {
  border-color: #efeeee;
  color: #efeeee;
}
.button.passive-button,
.menu-button.passive-button {
  background-color: #dfe7e3;
  border-left: none;
  cursor: default;
}
.button.passive-button:hover,
.menu-button.passive-button:hover {
  background-color: #dfe7e3;
}
.button.passive-button:active,
.menu-button.passive-button:active {
  background-color: #dfe7e3;
  color: inherit;
}
.button.passive-button:focus,
.menu-button.passive-button:focus {
  background-color: #dfe7e3;
  color: inherit;
}
.button[data-active=true],
.menu-button[data-active=true] {
  background-color: #c1d0c8;
  color: inherit;
}

.menu-button:focus,
.icon-button:focus {
  outline: none !important;
}

.link-button[data-disabled=true] {
  color: #999;
  cursor: not-allowed;
}

.link-button--no-underline {
  text-decoration: none !important;
}

button:focus {
  outline: 2px solid #000000 !important;
}

.menu-button {
  width: 35px;
  height: 35px;
  padding: 0 !important;
}
@media (min-width: 768px) {
  .menu-button {
    width: 40px;
    height: 40px;
  }
}

.menu-button-container {
  position: relative;
}

.menu-button__menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  cursor: default;
  border: 2px solid #000;
  padding: 16px 24px;
  z-index: 100;
  width: -webkit-min-content;
  width: min-content;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.menu-button__menu-close {
  cursor: pointer;
  align-self: flex-end;
}

.button:focus {
  outline: none !important;
}

button:focus {
  outline: 2px solid #000000 !important;
}

button:disabled {
  cursor: not-allowed;
}

.cart {
  margin-top: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .cart {
    margin-top: 30px;
  }
}

.cart__row td {
  padding-top: 15px;
}
@media (min-width: 768px) {
  .cart__row td {
    padding-top: 30px;
  }
}

.cart__row:first-of-type td {
  padding-top: 0;
}

.cart__row--bold td {
  font-weight: bold;
}

.cart__label {
  width: 60%;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .cart__label {
    padding-right: 30px;
  }
}

.cart__value {
  text-align: right;
}

.form__checkbox[data-disabled=true] {
  cursor: not-allowed !important;
  color: #888888;
}

.checkbox {
  position: relative;
  height: 22px;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  min-width: 22px;
  min-height: 22px;
}
.checkbox input[type=checkbox]:disabled {
  cursor: default !important;
}
.checkbox input[type=checkbox]:disabled ~ .checkbox-checkmark {
  cursor: not-allowed !important;
  color: #888888;
}
.checkbox input[type=checkbox] ~ .checkbox-checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #efeeee;
  border: 1px solid #000;
  transform: translateY(-50%);
}
.checkbox input[type=checkbox] ~ .checkbox-checkmark::after {
  content: "";
  position: absolute;
  box-sizing: content-box;
  display: none;
  left: 50%;
  top: 45%;
  width: 4px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.checkbox input[type=checkbox]:checked ~ .checkbox-checkmark {
  background-color: #c1d0c8;
}
.checkbox input[type=checkbox]:checked ~ .checkbox-checkmark:after {
  display: block;
}

.csv-importer-overlay {
  padding: 10%;
}

.csv-importer {
  background: white;
  border: 2px solid #fff;
}

.draggable-list {
  width: 100%;
}
.draggable-list .draggable-item {
  position: relative;
  width: 100%;
  box-sizing: content-box;
}
.draggable-list .draggable-item[draggable=true]:not([data-hide-icon=true]) .drag-icon {
  position: absolute;
  display: block;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.energy-tree-lock {
  position: absolute;
  right: 2.5rem;
  top: 50%;
  transform: translateY(calc(-50% + 0.5rem));
  z-index: 1;
}

.energy-tree-status {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: lightgray;
}
.energy-tree-status__ok {
  background-color: #090;
}
.energy-tree-status__nok {
  background-color: #900;
}

.energy-tree-node .form__input {
  padding-right: 1rem;
}
.energy-tree-node .form__input-field {
  text-align: right;
  padding-right: 36px;
}

.footer {
  background-color: #efeeee;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.footer__logo-list {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
@media (min-width: 768px) {
  .footer__logo-list {
    grid-gap: 40px;
    gap: 40px;
  }
}

.footer__logo {
  display: block;
}

.footer__logo--crb {
  width: 193px;
}
@media (min-width: 768px) {
  .footer__logo--crb {
    width: 210px;
  }
}
@media (min-width: 1200px) {
  .footer__logo--crb {
    width: 200px;
  }
}
@media (min-width: 1600px) {
  .footer__logo--crb {
    width: 226px;
  }
}

.footer__logo--werk {
  width: 91px;
  margin-left: auto;
}
@media (min-width: 576px) {
  .footer__logo--werk {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .footer__logo--werk {
    width: 100px;
  }
}
@media (min-width: 1200px) {
  .footer__logo--werk {
    width: 95px;
  }
}
@media (min-width: 1600px) {
  .footer__logo--werk {
    width: 108px;
  }
}

.footer__logo--sia {
  width: 210px;
}
@media (min-width: 576px) {
  .footer__logo--sia {
    margin-top: revert;
    margin-left: revert;
    margin-right: revert;
  }
}
@media (min-width: 768px) {
  .footer__logo--sia {
    width: 225px;
  }
}
@media (min-width: 1200px) {
  .footer__logo--sia {
    width: 235px;
  }
}
@media (min-width: 1600px) {
  .footer__logo--sia {
    width: 240px;
  }
}

.footer__logo--bfs {
  width: 290px;
}
@media (min-width: 768px) {
  .footer__logo--bfs {
    width: 311px;
  }
}
@media (min-width: 1200px) {
  .footer__logo--bfs {
    width: 300px;
    margin-top: revert;
    margin-left: revert;
    margin-right: revert;
  }
}
@media (min-width: 1600px) {
  .footer__logo--bfs {
    width: 332px;
  }
}

.forest-diagram,
.forest-diagram * {
  cursor: default;
}

.forest-diagram {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.forest-diagram__part {
  display: flex;
  width: 100%;
}

.forest-diagram-row {
  width: 100%;
  height: 1rem;
  display: flex;
}

.forest-diagram__cell, .forest-diagram__cell--invalid {
  height: 1rem;
  background-color: #c1d0c8;
  border: 1px #efeeee solid;
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  overflow: hidden;
}
.forest-diagram__cell--combined {
  border-right-width: 0px;
}
.forest-diagram__cell--invalid {
  background-color: #d79090;
}
.forest-diagram__cell--invalid.forest-diagram__cell--combined {
  border-left-width: 0px;
  border-right-width: 1px;
}
.forest-diagram__cell--empty {
  display: none;
}

.form__input {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}

.form__input--error:after {
  content: "";
  background-color: #d79090;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.form__input-field {
  position: relative;
  display: block;
  border: none !important;
  border-radius: 0;
  -webkit-appearance: none;
          appearance: none;
  padding-top: 23px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background: #efeeee;
  resize: none;
}
.form__input-field:disabled {
  cursor: not-allowed;
}
.form__input-field[type=number] {
  text-align: right;
  -moz-appearance: none;
}
.form__input-field[type=number]::-webkit-outer-spin-button, .form__input-field[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
}
.form__input-field[type=date] {
  color: rgba(0, 0, 0, 0);
  padding-top: 21px;
}
.form__input-field[type=date]:focus {
  color: unset;
}
.form__input-field[type=date]:focus:-ms-input-placeholder {
  opacity: 1;
}
.form__input-field[type=date]:focus::placeholder {
  opacity: 1;
}
.form__input-field[type=date]:-ms-input-placeholder {
  opacity: 0;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.form__input-field[type=date]::placeholder {
  opacity: 0;
  transition: all 0.3s;
}
.form__input-field[type=date].form__input-field--filled {
  color: unset;
}
.form__input-field.uuid-input {
  font-size: 20px;
  width: calc(32ch + 30px);
}
.form__input-field.invalid.touched {
  border-right: solid 10px #d79090 !important;
}

.form__input-field:focus {
  outline: none;
}

.form__input-label {
  display: inline-block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  margin: 0;
}
.form__input-label__small {
  font-size: small;
}

.form__input-label-content {
  color: #656464;
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  width: 100%;
  transform: translate(0, -50%);
  transition: all 0.3s;
}

.form__input-field:focus + .form__input-label .form__input-label-content,
.form__input-field--filled + .form__input-label .form__input-label-content {
  font-size: 15px;
  top: 0;
  transform: translate(0, 0);
}

.form__checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: left;
}
.form__checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.form__checkbox input:checked ~ .form__checkbox-checkmark {
  background-color: #c1d0c8;
}
.form__checkbox input:checked ~ .form__checkbox-checkmark:after {
  display: block;
}
.form__checkbox .form__checkbox-checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #efeeee;
  border: 1px solid #000;
  transform: translate(0, -50%);
}
.form__checkbox .form__checkbox-checkmark:after {
  content: "";
  position: absolute;
  box-sizing: content-box;
  display: none;
  left: 50%;
  top: 45%;
  width: 4px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.form__radio {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.form__radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.form__radio input:checked ~ .form__radio-checkmark {
  background-color: #c1d0c8;
}
.form__radio input:checked ~ .form__radio-checkmark:after {
  display: block;
}
.form__radio .form__radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #efeeee;
  border: 1px solid #000;
  border-radius: 50%;
}
.form__radio .form__radio-checkmark:after {
  content: "";
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: content-box;
  display: none;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -50%);
}
.form__radio .form__radio-checkmark--center {
  top: calc(50% - 11px);
}

.form__select {
  position: relative;
  display: block;
  border: none !important;
  border-radius: 0;
  -webkit-appearance: none;
          appearance: none;
  padding: 15px;
  width: 100%;
  background: #efeeee;
  resize: none;
  outline: none !important;
  min-height: 52px;
  padding-top: 23px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 100%;
  color: black;
  color: #000;
  text-shadow: 0 0 0 #000;
  overflow: visible;
}
.form__select.form__select--disabled, .form__select.form__select--disabled * {
  cursor: not-allowed;
}
.form__select.form__select--filled .form__select__label {
  font-size: 15px;
  top: 0;
  transform: translate(0, 0);
  left: 0;
  padding-left: 15px;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.form__select.form__select--no-label {
  padding-top: 19px;
}
.form__select.form__select--no-label::after {
  bottom: 10px;
}
.form__select .form__select__label {
  z-index: 1;
  color: #656464;
  position: absolute;
  left: 15px;
  top: 50%;
  display: block;
  width: 100%;
  transform: translate(0, -50%);
  transition: all 0.3s;
  margin-bottom: 0;
}
.form__select::after {
  content: url("/images/icons/arrow-down.svg");
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}
.form__select.form__search-select, .form__select.form__tag-select, .form__select.form__regulation-select {
  padding: 0;
}
.form__select.form__search-select .values [data-label], .form__select.form__tag-select .values [data-label], .form__select.form__regulation-select .values [data-label] {
  position: relative;
}
.form__select.form__search-select .values [data-label]:hover::after, .form__select.form__tag-select .values [data-label]:hover::after, .form__select.form__regulation-select .values [data-label]:hover::after {
  display: inline;
}
.form__select.form__search-select .form__search-select__option,
.form__select.form__search-select .form__tag-select__option,
.form__select.form__search-select .form__regulation-select__option, .form__select.form__tag-select .form__search-select__option,
.form__select.form__tag-select .form__tag-select__option,
.form__select.form__tag-select .form__regulation-select__option, .form__select.form__regulation-select .form__search-select__option,
.form__select.form__regulation-select .form__tag-select__option,
.form__select.form__regulation-select .form__regulation-select__option {
  position: relative;
  padding-right: 24px;
}
.form__select.form__search-select .form__search-select__option:hover,
.form__select.form__search-select .form__tag-select__option:hover,
.form__select.form__search-select .form__regulation-select__option:hover, .form__select.form__tag-select .form__search-select__option:hover,
.form__select.form__tag-select .form__tag-select__option:hover,
.form__select.form__tag-select .form__regulation-select__option:hover, .form__select.form__regulation-select .form__search-select__option:hover,
.form__select.form__regulation-select .form__tag-select__option:hover,
.form__select.form__regulation-select .form__regulation-select__option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.form__select.form__search-select .form__search-select__option button,
.form__select.form__search-select .form__tag-select__option button,
.form__select.form__search-select .form__regulation-select__option button, .form__select.form__tag-select .form__search-select__option button,
.form__select.form__tag-select .form__tag-select__option button,
.form__select.form__tag-select .form__regulation-select__option button, .form__select.form__regulation-select .form__search-select__option button,
.form__select.form__regulation-select .form__tag-select__option button,
.form__select.form__regulation-select .form__regulation-select__option button {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translate(50%, -50%);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: inherit;
}
.form__select.form__search-select .form__search-select__option button:hover,
.form__select.form__search-select .form__tag-select__option button:hover,
.form__select.form__search-select .form__regulation-select__option button:hover, .form__select.form__tag-select .form__search-select__option button:hover,
.form__select.form__tag-select .form__tag-select__option button:hover,
.form__select.form__tag-select .form__regulation-select__option button:hover, .form__select.form__regulation-select .form__search-select__option button:hover,
.form__select.form__regulation-select .form__tag-select__option button:hover,
.form__select.form__regulation-select .form__regulation-select__option button:hover {
  background: rgba(0, 0, 0, 0.1);
}
.form__select.form__search-select .select-menu, .form__select.form__tag-select .select-menu, .form__select.form__regulation-select .select-menu {
  margin-left: 0;
  width: 100%;
  transform: none;
}
.form__select.form__search-select .form__search-select__input,
.form__select.form__search-select .form__tag-select__input,
.form__select.form__search-select .form__regulation-select__input, .form__select.form__tag-select .form__search-select__input,
.form__select.form__tag-select .form__tag-select__input,
.form__select.form__tag-select .form__regulation-select__input, .form__select.form__regulation-select .form__search-select__input,
.form__select.form__regulation-select .form__tag-select__input,
.form__select.form__regulation-select .form__regulation-select__input {
  position: relative;
}
.form__select .select-option {
  position: relative;
  line-break: anywhere;
  word-break: break-word;
}
.form__select .select__option-title {
  font-size: 12px;
  color: #656464;
  font-weight: 500;
  position: absolute;
  top: 5px;
}
.form__select[data-focused=true] {
  z-index: 10;
}
.form__select .select-menu {
  position: absolute;
  z-index: 100;
  margin-left: -15px;
  top: 100%;
  width: 100%;
}
.form__select .select-menu .select-options {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.form__select .select-menu .select-options[data-align=center] {
  text-align: center;
}
.form__select .select-menu .select-options[data-align=right] {
  text-align: right;
}
.form__select .select-menu .select-options .select-option:hover {
  background: #dfe7e3;
}
.form__select .select-menu .select-options .select-option[data-selected=true] {
  background: #c1d0c8;
}
.form__select .select-menu .select-options .select-option[data-focused=true] {
  background: #dfe7e3;
}
.form__select .select-menu:required:invalid {
  font-weight: 300;
  color: #656464;
}

.form__error {
  color: #d79090;
  font-family: "Apercu";
}

.form__range .range-input__mouse-input {
  position: relative;
}
.form__range .range-input__slider {
  position: absolute;
  height: 100%;
  width: 16px;
  top: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  z-index: inherit;
}
.form__range .range-input__slider svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form__range .range-input__key-input {
  display: flex;
  justify-content: space-between;
}
.form__range .range-input__key-input input {
  width: 6ch;
  text-align: center;
}
.form__range .range-input__key-input input:last-of-type {
  text-align: right;
}

.header {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .header {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.header__home-link {
  display: block;
  width: 150px;
}
@media (min-width: 768px) {
  .header__home-link {
    width: 200px;
  }
}
@media (min-width: 1200px) {
  .header__home-link {
    width: 230px;
  }
}

.header__navigation-toggle {
  display: inline-block;
  margin-left: 10px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  width: 24px;
  height: 8px;
  position: relative;
  outline: none !important;
}
@media (min-width: 576px) {
  .header__navigation-toggle {
    margin-left: 45px;
    width: 40px;
    height: 12px;
  }
}
.header__navigation-toggle:before, .header__navigation-toggle:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #000;
  position: absolute;
  left: 0;
}
@media (min-width: 576px) {
  .header__navigation-toggle:before, .header__navigation-toggle:after {
    height: 3px;
  }
}
.header__navigation-toggle:before {
  top: 0;
}
.header__navigation-toggle:after {
  bottom: 0;
}
.header__navigation-toggle:focus {
  outline: none !important;
}

.image-canvas {
  position: relative;
  height: 100%;
  width: 100%;
}
.image-canvas .spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-canvas .image-canvas-error {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.image-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 1;
}

.image-box__close-button {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 40px;
  padding: 0;
  font-weight: bold;
  color: white;
  background: none;
  border: none;
  outline: none;
  width: 45px;
  height: 45px;
}

.image-box__container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  max-width: 90%;
  max-height: 90%;
  padding: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-box__image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.image-box__previous,
.image-box__next {
  top: 0;
  bottom: 0;
  width: 100px;
  position: absolute;
  background: rgba(127, 127, 127, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 40px;
}
.image-box__previous::after,
.image-box__next::after {
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  border: solid 4px transparent;
  transform: rotateZ(45deg);
}
.image-box__previous:hover,
.image-box__next:hover {
  background: rgba(127, 127, 127, 0.2);
  box-shadow: 0 6px 12px 0 #656464;
}

.image-box__previous {
  left: 0;
}
.image-box__previous::after {
  border-bottom-color: #fff;
  border-left-color: #fff;
  margin-left: 40px;
}

.image-box__next {
  right: 0;
}
.image-box__next::after {
  border-top-color: #fff;
  border-right-color: #fff;
  margin-left: 20px;
}

.lang-changer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5em;
  gap: 0.5em;
}
@media (min-width: 768px) {
  .lang-changer {
    grid-template-columns: repeat(4, 1fr);
  }
}
.lang-changer__out-of-view {
  position: fixed;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 2px 8px;
  background-color: white;
}
.lang-changer__out-of-view--collapsed {
  background-color: rgba(255, 255, 255, 0.5);
}

.lang-changer__lang {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  text-align: left;
}
.lang-changer__lang:hover, .lang-changer__lang:focus, .lang-changer__lang:active {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .lang-changer__lang {
    text-align: center;
  }
}

.lang-changer__lang--active {
  text-decoration: underline;
  pointer-events: none;
}
.lang-changer__lang--active:hover, .lang-changer__lang--active:focus, .lang-changer__lang--active:active {
  text-decoration: none;
}

.navigation {
  display: block;
}
.navigation__title {
  padding: 20px 15px;
}
@media (min-width: 768px) {
  .navigation__title {
    padding: 20px 30px;
  }
}
.navigation__title--hoverable:hover, .navigation__title--hoverable:focus, .navigation__title--hoverable:active {
  background-color: #c1d0c8;
  outline: none !important;
}

.navigation__list {
  border-top: 2px solid #000;
}

.navigation__list-item {
  border-bottom: 2px solid #000;
}

.navigation__link {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.3s;
}
@media (min-width: 768px) {
  .navigation__link {
    padding: 20px 30px;
  }
}
.navigation__link:hover, .navigation__link:focus, .navigation__link:active {
  background-color: #c1d0c8;
  outline: none !important;
}

.navigation__link--active {
  font-weight: bold;
  background-color: #c1d0c8;
}

.info-banner {
  background-color: #007cab;
  color: white;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
.info-banner * {
  margin-bottom: 0;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(223, 231, 227, 0.9);
  z-index: 100;
}

#project-editor .project-list__page-selector {
  min-width: 100px;
}
#project-editor .project-editor__action {
  color: #c1d0c8;
  font-weight: bolder;
  cursor: pointer;
}
#project-editor .project-editor-view.data-view .row {
  align-items: center;
}
#project-editor .project-editor-view.data-view .row.active {
  padding: 0;
}
#project-editor .project-editor-view.data-view .data-view__common {
  min-height: 62.5px;
  display: flex;
  align-items: center;
}
#project-editor .project-editor-view.data-view .form__input {
  margin-bottom: 0;
}
#project-editor .project-editor-view.data-view .form__select {
  margin-bottom: 0;
  display: inline-block;
}
#project-editor .project-editor-view.energy-view .row .form__input {
  margin-bottom: 0 !important;
}
#project-editor .project-editor-view.energy-view .energy-tree-metric-toggle {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
#project-editor .project-editor-view.building-view .new-floor .floor {
  border: 2px solid #000;
}
#project-editor .building-view-primary {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
#project-editor .building-editor--project .building-plan-importer {
  display: grid;
  grid-template: [row1start] "nav-state" [row1-end] [row2-start] "instructions" [row2-end] [row3-start] "error" [row3-end] [row4-start] "content" [row4-end] [row5-start] "nav-actions" [row5-end];
  grid-row-gap: 16px;
}
#project-editor .project-editor-view.media-view .media-list [data-selected=true] {
  background: #c1d0c8;
}
#project-editor .project-editor-view.media-view .media-list .draggable-item:first-of-type .media-item {
  background-color: #efeeee;
}
#project-editor .project-editor-view.media-view .media-list .draggable-item .media-item {
  padding-right: 48px;
}
#project-editor .project-editor-view.media-view .media-list .draggable-item .media-number {
  display: flex;
  align-items: center;
}
#project-editor .project-editor-view.media-view .media-list .draggable-item .media-preview-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
}
#project-editor .project-editor-view.media-view .upload-area {
  border: 2px solid #000;
}
#project-editor .project-editor-view.media-view .upload-area .image-upload__constraints {
  margin-top: 16px;
  line-height: 100%;
}
#project-editor .project-editor-view.media-view .image-preview {
  position: relative;
  overflow: hidden;
  background: #333;
}
#project-editor .project-editor-view.media-view .image-preview.light {
  background: #fff;
}
#project-editor .project-editor-view.media-view .image-preview.light .image-canvas .image-canvas-error {
  color: #333;
}
#project-editor .project-editor-view.media-view .image-preview .background-toggle {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
}
#project-editor .project-editor-view.media-view .image-preview .background-toggle[data-background=dark] {
  color: #fff;
}
#project-editor .project-editor-view.media-view .image-preview .image-canvas .image-canvas-error {
  color: #fff;
  font-weight: bold;
}
#project-editor .project-editor-view.media-view .image-preview img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
#project-editor .project-editor-view.media-view .image-preview .image-name {
  position: absolute;
  bottom: 8px;
  left: 8px;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 4px;
}
#project-editor .upload-area__error {
  color: #d79090;
  margin-top: 16px;
}
#project-editor .project-editor-view.cost-view .table-headers .col,
#project-editor .project-editor-view.cost-view .regulation-tree .col {
  display: flex;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
  word-break: break-word;
  white-space: break-spaces;
}
#project-editor .project-editor-view.cost-view .cost--inactive .cost-value {
  padding-right: 2em;
}
#project-editor .project-editor-view.cost-view .cost--inactive .cost-value .cost-value-lock {
  top: 50%;
  transform: translateY(-50%);
}
#project-editor .project-editor-view.cost-view .cost-value .form__input-field {
  padding-right: 2em;
  text-align: right;
}
#project-editor .project-editor-view.cost-view .cost {
  min-height: 62.5px;
}
#project-editor .project-editor-view.cost-view .cost .cost-value-lock {
  position: absolute;
  right: 0;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(-10px);
  top: 50%;
}
#project-editor .project-editor-view.cost-view .cost .status-bubble {
  position: relative;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid #888888;
}
#project-editor .project-editor-view.cost-view .cost .status-bubble[data-status=ok] {
  background-color: #090;
  border: none;
}
#project-editor .project-editor-view.cost-view .cost .status-bubble[data-status=warn] {
  background-color: #ebe0b9;
  border: none;
}
#project-editor .project-editor-view.cost-view .cost .status-bubble[data-status=nok] {
  background-color: #900;
  border: none;
}
#project-editor .wizard-underway-warning {
  color: #900;
}
#project-editor .wizard-underway-warning__text {
  margin-left: 10px;
}
#project-editor .project-editor-view.measurement-view .measurement .form__input {
  margin-bottom: 0;
}
#project-editor .project-editor-view.measurement-view .measurement .measurement-value-lock {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translate(0px, -50%);
  z-index: 1;
}
#project-editor .project-editor-view.measurement-view .measurement .status-bubble {
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translate(0px, -50%);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid #888888;
}
#project-editor .project-editor-view.measurement-view .measurement .status-bubble[data-status=ok] {
  background-color: #090;
  border: none;
}
#project-editor .project-editor-view.measurement-view .measurement .status-bubble[data-status=warn] {
  background-color: #ebe0b9;
  border: none;
}
#project-editor .project-editor-view.measurement-view .measurement .status-bubble[data-status=nok] {
  background-color: #900;
  border: none;
}
#project-editor .editor-floor__row-info {
  border-bottom: solid 1px #efeeee;
}
#project-editor .editor-floor__floor-list {
  font-size: 20px;
}
#project-editor .editor-floor__floor-list--multi .editor-floor__row-info {
  border-top: solid 1px black;
  padding-top: 20px;
  padding-bottom: 14px;
  margin-bottom: 12px;
}
#project-editor .editor-floor__floor-list .form__input-field {
  padding-top: 3px;
}
#project-editor .editor-floor__floor-list .form__input {
  margin-bottom: 0;
}
#project-editor .editor-floor__row-function {
  padding-top: 6px;
  padding-bottom: 6px;
}
#project-editor .editor-floor__row-add-function {
  padding-top: 6px;
  padding-bottom: 18px;
}
#project-editor .editor-floor__move-floor {
  display: inline-flex;
  flex-direction: column;
  height: 1rem;
  vertical-align: middle;
  font-weight: bold;
  margin-left: 12px;
}
#project-editor .editor-floor__move-floor-up {
  position: relative;
}
#project-editor .editor-floor__move-floor-up::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: solid 2px transparent;
  border-top-color: black;
  border-left-color: black;
  transform: rotate(45deg);
  cursor: pointer;
}
#project-editor .editor-floor__move-floor-up::after:hover {
  background-color: #efeeee;
}
#project-editor .editor-floor__move-floor-down {
  position: relative;
  vertical-align: sub;
}
#project-editor .editor-floor__move-floor-down::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border: solid 2px transparent;
  border-top-color: black;
  border-left-color: black;
  transform: rotate(225deg);
  cursor: pointer;
}
#project-editor .editor-floor__move-floor-down::after:hover {
  background-color: #efeeee;
}
#project-editor .editor-floor__action {
  color: #c1d0c8;
  font-weight: bolder;
  cursor: pointer;
}
#project-editor .project-table table tr th,
#project-editor .project-table table tr td {
  vertical-align: middle;
}
#project-editor .project-list-item.disabled {
  background-color: #efeeee;
  color: #656464;
}
#project-editor .project-list-item.selected {
  background-color: #dfe7e3;
}
#project-editor .project-list-item__thumbnail {
  height: 100px;
}
#project-editor .project-list-item__werkkategorie-title {
  font-size: 12px;
  color: #656464;
  font-weight: 500;
  top: 5px;
}
#project-editor .project-list-header--sortable {
  cursor: pointer;
}
#project-editor .project-list-header__content {
  display: inline-flex;
  width: 100%;
}
#project-editor .project-list-header__text {
  flex: 1 1;
}
#project-editor .project-list-header__sort-icon {
  align-self: center;
}
#project-editor .create-project-error {
  color: #900;
  margin-bottom: 16px;
}
#project-editor .create-project-error__text {
  margin-left: 10px;
}

.project-exporter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}
.project-exporter__spinner {
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
}
.project-exporter__spinner-text {
  position: absolute;
  margin: 0;
  font-size: 24;
  font-weight: bold;
  text-align: center;
}
.project-exporter--completed .project-exporter__spinner-text {
  position: relative;
}

.project-importer {
  display: grid;
  place-items: center;
  font-size: 18px;
  grid-gap: 2rem;
  gap: 2rem;
}
.project-importer p {
  margin-bottom: 0;
}
.project-importer__area {
  border: 1px dashed black;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  padding: 2rem 4rem;
  text-align: center;
  font-size: 24px;
}
.project-importer__result {
  text-align: left;
  width: 100%;
}

.project-share-overlay {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: rgba(193, 208, 200, 0.5);
  z-index: 10;
}
.project-share-overlay__close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.project-share-overlay .project-share__button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: grid;
  place-items: center;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
}

.project-share-form {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid black;
}
.project-share-form__licences {
  overflow-y: auto;
  overflow-x: visible;
  flex-shrink: 1;
  flex-grow: 1;
}
.project-share-form__entry {
  position: relative;
  align-items: center;
}
.project-share-form__entry--create .form__input {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
.project-share-form__entry .form__input {
  margin-bottom: 0;
}
.project-share-form__entry__check {
  color: green;
}
.project-share-form__entry__cross {
  color: red;
}
.project-share-form__entry__locked {
  color: grey;
}
.project-share-form .project-share__button {
  right: 4rem;
}

.scrollup-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 1rem;
  left: 100%;
  overflow: visible;
}

.scrollup-button {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
}

.sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 100%;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 15px 45px;
  overflow-y: scroll;
  transform: translate(100%, 0);
  transition: transform 0.3s;
}
@media (min-width: 576px) {
  .sidebar {
    padding: 30px 90px;
  }
}
@media (min-width: 768px) {
  .sidebar {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .sidebar {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .sidebar {
    padding: 60px 90px;
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .sidebar {
    width: 40%;
  }
}

.sidebar--open {
  transform: translate(0, 0);
}

.sidebar__close {
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  outline: none !important;
}
.sidebar__close:before, .sidebar__close:after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
}
.sidebar__close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.sidebar__close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.sidebar__header {
  margin-top: -24px;
}
.sidebar__header #userinfo__username {
  padding-right: 0.5rem;
}

.sidebar__content {
  margin-top: 45px;
}
@media (min-width: 768px) {
  .sidebar__content {
    margin-top: 75px;
  }
}
@media (min-width: 1200px) {
  .sidebar__content {
    margin-top: 75px;
  }
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #c1d0c8;
  z-index: -1;
  transition: all 0.3s;
}

.sidebar-overlay--show {
  z-index: 999;
  opacity: 0.7;
}

.slider-state-position[data-active=true] circle {
  fill: #c1d0c8;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
svg.spinner {
  animation: spin 1s infinite linear;
}

.tag-editor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(60%, max(100%, 400px));
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
}
.tag-editor__header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.tag-editor__header h3 {
  margin: 0;
}
.tag-editor__body {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
  max-height: 50vh;
}

.dialog__overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.dialog__content {
  background-color: #fff;
  min-width: 350px;
  min-height: 200px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.dialog__header {
  font-size: 28px;
  padding: 16px;
  border-bottom: solid 1px black;
}

.dialog__body {
  font-size: 24px;
  padding: 16px;
  flex-grow: 1;
}

.dialog__footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
}

.toast__content {
  position: fixed;
  z-index: 100;
  top: 10%;
  left: 50%;
  width: 300px;
  min-height: 200px;
  margin-left: -150px;
  background-color: #fff;
  box-shadow: 0 0 10px 10px #333;
}

.toast__header {
  font-size: 28px;
  padding: 16px;
  border-bottom: solid 1px black;
}

.toast__body {
  font-size: 24px;
  padding: 16px;
  flex-grow: 1;
}

#tableau-container {
  position: relative;
}

#tableau-container[data-scale] iframe {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) translate(0, 50%);
}

#bkp-to-ebkph-wizard .building-plan-estimator {
  overflow: hidden;
  height: 350px;
  width: 350px;
}
#bkp-to-ebkph-wizard .building-plan-estimator canvas {
  height: 350px;
  width: 350px;
}
#bkp-to-ebkph-wizard .content-slider {
  display: flex;
}
#bkp-to-ebkph-wizard .content-slider__scroll-button {
  border: none;
  border-radius: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 50px;
  color: #000;
  outline: none;
}
#bkp-to-ebkph-wizard .content-slider__slider {
  flex: 1 1;
  overflow: hidden;
  margin: 0;
}
#bkp-to-ebkph-wizard .content-slider__content {
  white-space: nowrap;
}
#bkp-to-ebkph-wizard .progress {
  border-radius: 0;
  height: 50px;
}
#bkp-to-ebkph-wizard .progress-bar {
  color: #000;
  font-size: 16px;
}
#bkp-to-ebkph-wizard .progress-bar.bg-secondary {
  color: #fff;
}
.progress-bar-tooltip .tooltip-inner {
  max-width: none !important;
}

#bkp-to-ebkph-wizard .rc-slider.range-slider {
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  background: #efeeee;
  height: 3em;
  padding: 0;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-rail {
  display: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-track {
  display: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-step {
  display: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-handle {
  border: none;
  border-radius: 0;
  background-image: url(/static/media/slider-handle.4fbe5270.svg);
  color: #fff;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  box-shadow: none !important;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-handle:hover {
  box-shadow: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-handle::before {
  display: block;
  content: "";
  background: #000;
  width: 3px;
  height: 54px;
  position: relative;
  left: 11px;
  top: -14px;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-handle::after {
  display: block;
  content: attr("aria-valuenow");
  color: red;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-tooltip {
  padding: 0;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-tooltip-inner {
  border-radius: 0;
  background: #000;
  color: #fff;
  font-weight: bold;
  padding: 0.18em 0.3em;
  font-size: 15px;
  line-height: 1;
  height: 1.4em;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider .rc-slider-tooltip-arrow {
  display: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider--disabled {
  background-color: #c4d0c9;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider--disabled .rc-slider-handle {
  background: none;
}
#bkp-to-ebkph-wizard .rc-slider.range-slider--disabled .rc-slider-tooltip {
  top: 17px !important;
}
#bkp-to-ebkph-wizard .rc-slider--hidden [role=slider] {
  visibility: hidden;
}
#bkp-to-ebkph-wizard .rc-slider--hidden .rc-slider-tooltip {
  visibility: hidden;
}
#bkp-to-ebkph-wizard .slider-wrapper {
  position: relative;
  width: 60%;
}
#bkp-to-ebkph-wizard .slider-wrapper .slider-value {
  position: absolute;
  top: -20px;
  font-size: small;
  color: #7e7979;
  font-weight: bolder;
}
#bkp-to-ebkph-wizard .slider-wrapper .slider-value.slider-value-right {
  right: 0;
}
#bkp-to-ebkph-wizard .slider-wrapper .rc-slider {
  width: 100%;
}
#bkp-to-ebkph-wizard .status-indicator {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: solid 2px #000;
  margin-right: 20px;
  padding: 4px 0;
  cursor: pointer;
  min-width: 150px;
}
#bkp-to-ebkph-wizard .status-indicator__label {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
}
#bkp-to-ebkph-wizard .status-indicator__label::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  content: " ";
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: solid 2px black;
  margin-right: 0.5em;
  text-align: center;
  font-family: monospace;
  line-height: 1em;
  font-size: 12px;
}
#bkp-to-ebkph-wizard .status-indicator--todo {
  border-top-color: #000;
}
#bkp-to-ebkph-wizard .status-indicator--in-progress {
  border-top-color: #c1d0c8;
  font-weight: bold;
}
#bkp-to-ebkph-wizard .status-indicator--in-progress .status-indicator__label::before {
  background-color: #fff;
  border: solid 6px #c1d0c8;
}
#bkp-to-ebkph-wizard .status-indicator--ready {
  border-top-color: #c1d0c8;
  font-weight: bold;
}
#bkp-to-ebkph-wizard .status-indicator--ready .status-indicator__label::before {
  background-color: #c1d0c8;
  border: solid 6px #c1d0c8;
}
#bkp-to-ebkph-wizard .status-indicator--done {
  border-top-color: #000;
}
#bkp-to-ebkph-wizard .status-indicator--done .status-indicator__label::before {
  color: white;
  background-color: #000;
  content: "✓";
}
#bkp-to-ebkph-wizard .wizard-save-button {
  background-color: #ffffff;
}
#bkp-to-ebkph-wizard .hvalue-row {
  display: inline-flex;
  width: 100%;
  background-color: #ffffff;
}
#bkp-to-ebkph-wizard .h-value-value {
  flex: 1 0 50%;
  display: inline-flex;
  min-height: 55px;
  justify-content: flex-end;
}
#bkp-to-ebkph-wizard .wizard-nav {
  margin-top: 16px;
  display: flex;
}
#bkp-to-ebkph-wizard .wizard-nav > * {
  flex: 1 1 100%;
  border: solid 2px black;
  border-left-color: transparent;
  border-right-color: transparent;
  margin: 0 5px;
  outline: none;
  padding: 0;
  height: inherit;
}
#bkp-to-ebkph-wizard .wizard-nav > *:first-child {
  border-left-color: black;
  margin-left: 0;
}
#bkp-to-ebkph-wizard .wizard-nav > *:first-child svg:first-child {
  display: none;
}
#bkp-to-ebkph-wizard .wizard-nav > *:last-child {
  border-right-color: black;
  margin-right: 0;
}
#bkp-to-ebkph-wizard .wizard-nav > *:last-child svg:last-child {
  display: none;
}
#bkp-to-ebkph-wizard .wizard-nav-item {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
#bkp-to-ebkph-wizard .wizard-nav-item:focus {
  outline: none !important;
}
#bkp-to-ebkph-wizard .wizard-nav-item__svg-arrow {
  position: absolute;
  top: -2px;
}
#bkp-to-ebkph-wizard .wizard-nav-item__svg-arrow path {
  stroke: #000;
  stroke-width: 2;
  fill: #fff;
}
#bkp-to-ebkph-wizard .wizard-nav-item__svg-arrow:first-child {
  left: -2px;
  z-index: 1;
}
#bkp-to-ebkph-wizard .wizard-nav-item__svg-arrow:last-child {
  right: 0;
  transform: translateX(100%);
  z-index: 2;
}
#bkp-to-ebkph-wizard .wizard-nav-item__svg-arrow:last-child path {
  fill: #fff;
}
#bkp-to-ebkph-wizard .wizard-nav-item--active {
  background-color: #c1d0c8;
  font-weight: bold;
}
#bkp-to-ebkph-wizard .wizard-nav-item--active .wizard-nav-item__svg-arrow:last-child path {
  fill: #c1d0c8;
}
#bkp-to-ebkph-wizard .wizard-nav-item__label {
  flex: 1 1;
}
#bkp-to-ebkph-wizard .regulation-statistics {
  display: flex;
}
#bkp-to-ebkph-wizard .regulation-statistics__label {
  margin: auto 0 auto 5px;
  line-height: 1em;
  color: #656464;
}
#bkp-to-ebkph-wizard .regulation-statistics__mean {
  float: right;
  transition: all 0.3s ease-out;
  transition-delay: 0.25s;
}
#bkp-to-ebkph-wizard .regulation-statistics__mean--hidden {
  opacity: 0;
}
#bkp-to-ebkph-wizard .regulation-statistics__spacing,
#bkp-to-ebkph-wizard .regulation-statistics__label {
  width: 20%;
}
#bkp-to-ebkph-wizard .regulation-statistics__label,
#bkp-to-ebkph-wizard .regulation-statistics__statistic {
  margin-left: 5px;
}
#bkp-to-ebkph-wizard .regulation-statistics__statistic {
  width: 60%;
  height: 2em;
}
#bkp-to-ebkph-wizard .wizard-tree-row {
  display: flex;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
}
#bkp-to-ebkph-wizard .drawer-row-content {
  display: inline-flex;
  flex: 1 1;
  align-content: center;
  align-items: center;
  padding-left: 20px;
}
#bkp-to-ebkph-wizard .drawer-headers {
  font-weight: bold;
  display: flex;
  border-bottom: 1px solid #656464;
}
#bkp-to-ebkph-wizard .drawer-header {
  text-align: center;
}
#bkp-to-ebkph-wizard .drawer-header-code-description {
  flex: 1.5 1;
  margin-right: 50px;
}
#bkp-to-ebkph-wizard .drawer-header-comparison {
  flex: 3 1;
}
#bkp-to-ebkph-wizard .drawer-header-quantity-amount {
  flex: 1 1;
}
#bkp-to-ebkph-wizard .drawer-row-regulation-code {
  flex: 0 1 50px;
}
#bkp-to-ebkph-wizard .drawer-row-regulation {
  flex: 1 1;
  display: inline-flex;
}
#bkp-to-ebkph-wizard .regulation-title-short-name {
  flex: 2 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#bkp-to-ebkph-wizard .regulation-short-name {
  width: 40%;
  text-align: center;
  margin-right: 5px;
}
#bkp-to-ebkph-wizard .regulation-title {
  flex: 2 2;
  display: flex;
  align-items: center;
}
#bkp-to-ebkph-wizard .regulation-mapping {
  flex: 1 0;
  display: inline-flex;
  justify-content: flex-end;
}
#bkp-to-ebkph-wizard .regulation-mapping--hidden {
  opacity: 0;
}
#bkp-to-ebkph-wizard .regulation-cost-data {
  position: relative;
  flex: 1 0 50%;
  display: inline-flex;
  flex-direction: column;
}
#bkp-to-ebkph-wizard .regulation-cost-data .box-plot__median {
  height: 4.7em;
  transform: translate(0, -3.5em);
}
#bkp-to-ebkph-wizard .regulation-value {
  flex: 1 1;
  display: inline-flex;
}
#bkp-to-ebkph-wizard .regulation-value__item {
  width: 20%;
  text-align: end;
  margin-right: 5px;
  height: 3em;
}
#bkp-to-ebkph-wizard .regulation-value__mappings {
  width: 100%;
  height: 3em;
  margin-right: 5px;
}
#bkp-to-ebkph-wizard .regulation-value__mappings .cost-value__value {
  font-size: 14px;
}
#bkp-to-ebkph-wizard .regulation-value__item-min {
  width: -webkit-min-content;
  width: min-content;
  text-align: end;
  margin-right: 5px;
}
#bkp-to-ebkph-wizard .regulation-value__slider {
  width: 60%;
}
#bkp-to-ebkph-wizard .drawer-row-action-buttons {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0.1 1;
}
#bkp-to-ebkph-wizard .drawer-row-action-button {
  justify-content: space-between;
  display: flex;
  width: 60px;
  text-align: end;
  cursor: pointer;
}
#bkp-to-ebkph-wizard .drawer-row-action-button__icon {
  border: solid 1px #000000;
  color: #000000;
  padding: 2px;
}
#bkp-to-ebkph-wizard .drawer-row-action-button__icon--locked {
  background-color: #c4d0c9;
}
#bkp-to-ebkph-wizard .drawer-child {
  margin-left: 50px;
}
#bkp-to-ebkph-wizard .drawer-icon {
  width: 20px;
  height: 20px;
  align-self: center;
}
#bkp-to-ebkph-wizard .drawer-collapser {
  margin-left: 10px;
}
#bkp-to-ebkph-wizard .regulation-state {
  height: 100%;
  flex: 0 1;
  padding: 25px 2px;
}
#bkp-to-ebkph-wizard .regulation-state--unknown {
  background-color: #efeeee;
}
#bkp-to-ebkph-wizard .regulation-state--okay {
  background-color: #e1e7e3;
}
#bkp-to-ebkph-wizard .regulation-state--warning {
  background-color: #ebe0b9;
}
#bkp-to-ebkph-wizard .regulation-state--error {
  background-color: #d79090;
}
#bkp-to-ebkph-wizard .cost-value {
  display: flex;
  flex-direction: column;
  background-color: #efeeee;
  padding: 2px 5px;
}
#bkp-to-ebkph-wizard .cost-value__label {
  flex: 1 1;
  text-align: start;
  white-space: nowrap;
  font-size: small;
}
#bkp-to-ebkph-wizard .cost-value__value {
  flex: 1 1;
  position: relative;
}
#bkp-to-ebkph-wizard .cost-value__value span {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 100%;
  text-align: right;
}
#bkp-to-ebkph-wizard .cost-value__input-field {
  line-height: 1;
  font-weight: inherit;
  text-align: right;
  padding: unset;
  bottom: -2px;
}
#bkp-to-ebkph-wizard .cost-value--locked {
  background-color: #c4d0c9;
  color: #000000;
}
#bkp-to-ebkph-wizard label.cost-value__label {
  display: block;
}
#bkp-to-ebkph-wizard .ebkph-tree-view__header .ebkp-tree-filter-entry .form__input,
#bkp-to-ebkph-wizard .ebkph-tree-view__header .ebkp-tree-filter-entry .form__checkbox {
  margin: 0;
}
#bkp-to-ebkph-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu section:first-of-type {
  margin-top: 0 !important;
}
#bkp-to-ebkph-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu__section-icon {
  height: 20px;
  width: 20px;
  margin-right: 25px;
}
#bkp-to-ebkph-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu__section-entries {
  margin-left: 45px;
}
#bkp-to-ebkph-wizard .ebkph-tree-view__header .menu-button,
#bkp-to-ebkph-wizard .ebkph-tree-view__header .icon-button {
  height: 40px;
  width: 40px;
}
#bkp-to-ebkph-wizard .volume-evaluation-wizard .building-plan-wrapper {
  overflow: hidden;
  height: 630px;
}
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(1),
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(1) {
  width: 42px;
}
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(3),
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(3) {
  width: 20%;
}
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(4),
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(4) {
  width: 20%;
}
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-creation-form .table-border-section td,
#bkp-to-ebkph-wizard .volume-evaluation-wizard .model-calculation-results .table-border-section td {
  border-top: 1px black solid;
}
#bkp-to-ebkph-wizard .building-editor--wizard .building-plan-importer {
  display: grid;
  grid-template: [row1start] "nav-actions" [row1-end] [row2-start] "error" [row2-end] [row3-start] "content" [row3-end] [row4-start] "instructions" [row4-end] [row5-start] "nav-state" [row5-end];
  grid-row-gap: 16px;
}
#bkp-to-ebkph-wizard .estimation-plausibility .categories-tableau-container {
  position: relative;
}
#bkp-to-ebkph-wizard .estimation-plausibility .category-row {
  display: flex;
}
#bkp-to-ebkph-wizard .estimation-plausibility .category-row .category-content {
  width: 100%;
}
#bkp-to-ebkph-wizard .estimation-plausibility .category-row .category-description {
  display: none;
}
#bkp-to-ebkph-wizard .estimation-plausibility .tableau-wrapper {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 100%;
}
#bkp-to-ebkph-wizard .estimation-plausibility--with-description .category-row .category-content {
  width: 70%;
}
#bkp-to-ebkph-wizard .estimation-plausibility--with-description .category-row .category-description {
  display: block;
  width: 30%;
}
#bkp-to-ebkph-wizard .estimation-plausibility--with-tableau .tableau-wrapper {
  position: static;
}
#bkp-to-ebkph-wizard .estimation-plausibility--with-tableau .categories-wrapper {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
#bkp-to-ebkph-wizard .icon-buttons {
  display: flex;
  justify-content: flex-end;
}
#bkp-to-ebkph-wizard .icon-buttons .button-group {
  margin-right: 100px;
}
#bkp-to-ebkph-wizard .icon-buttons .button-group button:nth-of-type(1) {
  border-right: 0;
}
#bkp-to-ebkph-wizard .icon-buttons .menu-button + .menu-button {
  margin-left: 100px;
}
#bkp-to-ebkph-wizard .tableau-select-reference-projects {
  /*
    Need to algin this element with tableau iframe. 
    iframe content has a fixed width of 1200px, in larger screen it will create horizontal padding 
  */
  padding-left: calc((100% - 1200px) / 2);
}
#bkp-to-ebkph-wizard .tableau-select-reference-projects--error {
  color: #900;
}
#bkp-to-ebkph-wizard .tableau-navigation {
  flex: 1 1;
  padding-top: calc(1rem + 40px);
}
#bkp-to-ebkph-wizard .tableau-navigation .button {
  padding: 10px;
}
#bkp-to-ebkph-wizard .tableau-navigation__button--active {
  background-color: #c1d0c8;
}
#bkp-to-ebkph-wizard .category-checkbox__label {
  display: flex;
}
#bkp-to-ebkph-wizard .category-checkbox__label--code {
  min-width: 20px;
}
#bkp-to-ebkph-wizard .category-checkbox__label--description {
  font-size: smaller;
}
#bkp-to-ebkph-wizard .category-description__with-text-area {
  padding: 1rem 1rem 1rem 0;
  background-color: #fff;
}
#bkp-to-ebkph-wizard .category-description__with-text-area .form__input {
  height: 100%;
  font-size: smaller;
}
#bkp-to-ebkph-wizard .category-description__with-text-area .form__input-field {
  height: 100%;
}

#estimaton-wizard .building-plan-estimator {
  overflow: hidden;
  height: 350px;
  width: 350px;
}
#estimaton-wizard .building-plan-estimator canvas {
  height: 350px;
  width: 350px;
}
#estimaton-wizard .content-slider {
  display: flex;
}
#estimaton-wizard .content-slider__scroll-button {
  border: none;
  border-radius: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 50px;
  color: #000;
  outline: none;
}
#estimaton-wizard .content-slider__slider {
  flex: 1 1;
  overflow: hidden;
  margin: 0;
}
#estimaton-wizard .content-slider__content {
  white-space: nowrap;
}
#estimaton-wizard .progress {
  border-radius: 0;
  height: 50px;
}
#estimaton-wizard .progress-bar {
  color: #000;
  font-size: 16px;
}
#estimaton-wizard .progress-bar.bg-secondary {
  color: #fff;
}
.progress-bar-tooltip .tooltip-inner {
  max-width: none !important;
}

#estimaton-wizard .rc-slider.range-slider {
  display: inline-flex;
  align-items: center;
  border-radius: 0;
  background: #efeeee;
  height: 3em;
  padding: 0;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-rail {
  display: none;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-track {
  display: none;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-step {
  display: none;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-handle {
  border: none;
  border-radius: 0;
  background-image: url(/static/media/slider-handle.4fbe5270.svg);
  color: #fff;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  box-shadow: none !important;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-handle:hover {
  box-shadow: none;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-handle::before {
  display: block;
  content: "";
  background: #000;
  width: 3px;
  height: 54px;
  position: relative;
  left: 11px;
  top: -14px;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-handle::after {
  display: block;
  content: attr("aria-valuenow");
  color: red;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-tooltip {
  padding: 0;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-tooltip-inner {
  border-radius: 0;
  background: #000;
  color: #fff;
  font-weight: bold;
  padding: 0.18em 0.3em;
  font-size: 15px;
  line-height: 1;
  height: 1.4em;
}
#estimaton-wizard .rc-slider.range-slider .rc-slider-tooltip-arrow {
  display: none;
}
#estimaton-wizard .rc-slider.range-slider--disabled {
  background-color: #c4d0c9;
}
#estimaton-wizard .rc-slider.range-slider--disabled .rc-slider-handle {
  background: none;
}
#estimaton-wizard .rc-slider.range-slider--disabled .rc-slider-tooltip {
  top: 17px !important;
}
#estimaton-wizard .rc-slider--hidden [role=slider] {
  visibility: hidden;
}
#estimaton-wizard .rc-slider--hidden .rc-slider-tooltip {
  visibility: hidden;
}
#estimaton-wizard .slider-wrapper {
  position: relative;
  width: 60%;
}
#estimaton-wizard .slider-wrapper .slider-value {
  position: absolute;
  top: -20px;
  font-size: small;
  color: #7e7979;
  font-weight: bolder;
}
#estimaton-wizard .slider-wrapper .slider-value.slider-value-right {
  right: 0;
}
#estimaton-wizard .slider-wrapper .rc-slider {
  width: 100%;
}
#estimaton-wizard .status-indicator {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-top: solid 2px #000;
  margin-right: 20px;
  padding: 4px 0;
  cursor: pointer;
  min-width: 150px;
}
#estimaton-wizard .status-indicator__label {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
}
#estimaton-wizard .status-indicator__label::before {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  content: " ";
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: solid 2px black;
  margin-right: 0.5em;
  text-align: center;
  font-family: monospace;
  line-height: 1em;
  font-size: 12px;
}
#estimaton-wizard .status-indicator--todo {
  border-top-color: #000;
}
#estimaton-wizard .status-indicator--in-progress {
  border-top-color: #c1d0c8;
  font-weight: bold;
}
#estimaton-wizard .status-indicator--in-progress .status-indicator__label::before {
  background-color: #fff;
  border: solid 6px #c1d0c8;
}
#estimaton-wizard .status-indicator--ready {
  border-top-color: #c1d0c8;
  font-weight: bold;
}
#estimaton-wizard .status-indicator--ready .status-indicator__label::before {
  background-color: #c1d0c8;
  border: solid 6px #c1d0c8;
}
#estimaton-wizard .status-indicator--done {
  border-top-color: #000;
}
#estimaton-wizard .status-indicator--done .status-indicator__label::before {
  color: white;
  background-color: #000;
  content: "✓";
}
#estimaton-wizard .wizard-save-button {
  background-color: #ffffff;
}
#estimaton-wizard .hvalue-row {
  display: inline-flex;
  width: 100%;
  background-color: #ffffff;
}
#estimaton-wizard .h-value-value {
  flex: 1 0 50%;
  display: inline-flex;
  min-height: 55px;
  justify-content: flex-end;
}
#estimaton-wizard .wizard-nav {
  margin-top: 16px;
  display: flex;
}
#estimaton-wizard .wizard-nav > * {
  flex: 1 1 100%;
  border: solid 2px black;
  border-left-color: transparent;
  border-right-color: transparent;
  margin: 0 5px;
  outline: none;
  padding: 0;
  height: inherit;
}
#estimaton-wizard .wizard-nav > *:first-child {
  border-left-color: black;
  margin-left: 0;
}
#estimaton-wizard .wizard-nav > *:first-child svg:first-child {
  display: none;
}
#estimaton-wizard .wizard-nav > *:last-child {
  border-right-color: black;
  margin-right: 0;
}
#estimaton-wizard .wizard-nav > *:last-child svg:last-child {
  display: none;
}
#estimaton-wizard .wizard-nav-item {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
#estimaton-wizard .wizard-nav-item:focus {
  outline: none !important;
}
#estimaton-wizard .wizard-nav-item__svg-arrow {
  position: absolute;
  top: -2px;
}
#estimaton-wizard .wizard-nav-item__svg-arrow path {
  stroke: #000;
  stroke-width: 2;
  fill: #fff;
}
#estimaton-wizard .wizard-nav-item__svg-arrow:first-child {
  left: -2px;
  z-index: 1;
}
#estimaton-wizard .wizard-nav-item__svg-arrow:last-child {
  right: 0;
  transform: translateX(100%);
  z-index: 2;
}
#estimaton-wizard .wizard-nav-item__svg-arrow:last-child path {
  fill: #fff;
}
#estimaton-wizard .wizard-nav-item--active {
  background-color: #c1d0c8;
  font-weight: bold;
}
#estimaton-wizard .wizard-nav-item--active .wizard-nav-item__svg-arrow:last-child path {
  fill: #c1d0c8;
}
#estimaton-wizard .wizard-nav-item__label {
  flex: 1 1;
}
#estimaton-wizard .regulation-statistics {
  display: flex;
}
#estimaton-wizard .regulation-statistics__label {
  margin: auto 0 auto 5px;
  line-height: 1em;
  color: #656464;
}
#estimaton-wizard .regulation-statistics__mean {
  float: right;
  transition: all 0.3s ease-out;
  transition-delay: 0.25s;
}
#estimaton-wizard .regulation-statistics__mean--hidden {
  opacity: 0;
}
#estimaton-wizard .regulation-statistics__spacing,
#estimaton-wizard .regulation-statistics__label {
  width: 20%;
}
#estimaton-wizard .regulation-statistics__label,
#estimaton-wizard .regulation-statistics__statistic {
  margin-left: 5px;
}
#estimaton-wizard .regulation-statistics__statistic {
  width: 60%;
  height: 2em;
}
#estimaton-wizard .wizard-tree-row {
  display: flex;
  align-content: center;
  align-items: center;
  background-color: #ffffff;
}
#estimaton-wizard .drawer-row-content {
  display: inline-flex;
  flex: 1 1;
  align-content: center;
  align-items: center;
  padding-left: 20px;
}
#estimaton-wizard .drawer-headers {
  font-weight: bold;
  display: flex;
  border-bottom: 1px solid #656464;
}
#estimaton-wizard .drawer-header {
  text-align: center;
}
#estimaton-wizard .drawer-header-code-description {
  flex: 1.5 1;
  margin-right: 50px;
}
#estimaton-wizard .drawer-header-comparison {
  flex: 3 1;
}
#estimaton-wizard .drawer-header-quantity-amount {
  flex: 1 1;
}
#estimaton-wizard .drawer-row-regulation-code {
  flex: 0 1 50px;
}
#estimaton-wizard .drawer-row-regulation {
  flex: 1 1;
  display: inline-flex;
}
#estimaton-wizard .regulation-title-short-name {
  flex: 2 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#estimaton-wizard .regulation-short-name {
  width: 40%;
  text-align: center;
  margin-right: 5px;
}
#estimaton-wizard .regulation-title {
  flex: 2 2;
  display: flex;
  align-items: center;
}
#estimaton-wizard .regulation-mapping {
  flex: 1 0;
  display: inline-flex;
  justify-content: flex-end;
}
#estimaton-wizard .regulation-mapping--hidden {
  opacity: 0;
}
#estimaton-wizard .regulation-cost-data {
  position: relative;
  flex: 1 0 50%;
  display: inline-flex;
  flex-direction: column;
}
#estimaton-wizard .regulation-cost-data .box-plot__median {
  height: 4.7em;
  transform: translate(0, -3.5em);
}
#estimaton-wizard .regulation-value {
  flex: 1 1;
  display: inline-flex;
}
#estimaton-wizard .regulation-value__item {
  width: 20%;
  text-align: end;
  margin-right: 5px;
  height: 3em;
}
#estimaton-wizard .regulation-value__mappings {
  width: 100%;
  height: 3em;
  margin-right: 5px;
}
#estimaton-wizard .regulation-value__mappings .cost-value__value {
  font-size: 14px;
}
#estimaton-wizard .regulation-value__item-min {
  width: -webkit-min-content;
  width: min-content;
  text-align: end;
  margin-right: 5px;
}
#estimaton-wizard .regulation-value__slider {
  width: 60%;
}
#estimaton-wizard .drawer-row-action-buttons {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0.1 1;
}
#estimaton-wizard .drawer-row-action-button {
  justify-content: space-between;
  display: flex;
  width: 60px;
  text-align: end;
  cursor: pointer;
}
#estimaton-wizard .drawer-row-action-button__icon {
  border: solid 1px #000000;
  color: #000000;
  padding: 2px;
}
#estimaton-wizard .drawer-row-action-button__icon--locked {
  background-color: #c4d0c9;
}
#estimaton-wizard .drawer-child {
  margin-left: 50px;
}
#estimaton-wizard .drawer-icon {
  width: 20px;
  height: 20px;
  align-self: center;
}
#estimaton-wizard .drawer-collapser {
  margin-left: 10px;
}
#estimaton-wizard .regulation-state {
  height: 100%;
  flex: 0 1;
  padding: 25px 2px;
}
#estimaton-wizard .regulation-state--unknown {
  background-color: #efeeee;
}
#estimaton-wizard .regulation-state--okay {
  background-color: #e1e7e3;
}
#estimaton-wizard .regulation-state--warning {
  background-color: #ebe0b9;
}
#estimaton-wizard .regulation-state--error {
  background-color: #d79090;
}
#estimaton-wizard .cost-value {
  display: flex;
  flex-direction: column;
  background-color: #efeeee;
  padding: 2px 5px;
}
#estimaton-wizard .cost-value__label {
  flex: 1 1;
  text-align: start;
  white-space: nowrap;
  font-size: small;
}
#estimaton-wizard .cost-value__value {
  flex: 1 1;
  position: relative;
}
#estimaton-wizard .cost-value__value span {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 100%;
  text-align: right;
}
#estimaton-wizard .cost-value__input-field {
  line-height: 1;
  font-weight: inherit;
  text-align: right;
  padding: unset;
  bottom: -2px;
}
#estimaton-wizard .cost-value--locked {
  background-color: #c4d0c9;
  color: #000000;
}
#estimaton-wizard label.cost-value__label {
  display: block;
}
#estimaton-wizard .ebkph-tree-view__header .ebkp-tree-filter-entry .form__input,
#estimaton-wizard .ebkph-tree-view__header .ebkp-tree-filter-entry .form__checkbox {
  margin: 0;
}
#estimaton-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu section:first-of-type {
  margin-top: 0 !important;
}
#estimaton-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu__section-icon {
  height: 20px;
  width: 20px;
  margin-right: 25px;
}
#estimaton-wizard .ebkph-tree-view__header .ebkp-tree-view-filter-menu__section-entries {
  margin-left: 45px;
}
#estimaton-wizard .ebkph-tree-view__header .menu-button,
#estimaton-wizard .ebkph-tree-view__header .icon-button {
  height: 40px;
  width: 40px;
}
#estimaton-wizard .volume-evaluation-wizard .building-plan-wrapper {
  overflow: hidden;
  height: 630px;
}
#estimaton-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(1),
#estimaton-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(1) {
  width: 42px;
}
#estimaton-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(3),
#estimaton-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(3) {
  width: 20%;
}
#estimaton-wizard .volume-evaluation-wizard .model-creation-form thead th:nth-child(4),
#estimaton-wizard .volume-evaluation-wizard .model-calculation-results thead th:nth-child(4) {
  width: 20%;
}
#estimaton-wizard .volume-evaluation-wizard .model-creation-form .table-border-section td,
#estimaton-wizard .volume-evaluation-wizard .model-calculation-results .table-border-section td {
  border-top: 1px black solid;
}
#estimaton-wizard .building-editor--wizard .building-plan-importer {
  display: grid;
  grid-template: [row1start] "nav-actions" [row1-end] [row2-start] "error" [row2-end] [row3-start] "content" [row3-end] [row4-start] "instructions" [row4-end] [row5-start] "nav-state" [row5-end];
  grid-row-gap: 16px;
}
#estimaton-wizard .estimation-plausibility .categories-tableau-container {
  position: relative;
}
#estimaton-wizard .estimation-plausibility .category-row {
  display: flex;
}
#estimaton-wizard .estimation-plausibility .category-row .category-content {
  width: 100%;
}
#estimaton-wizard .estimation-plausibility .category-row .category-description {
  display: none;
}
#estimaton-wizard .estimation-plausibility .tableau-wrapper {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 100%;
}
#estimaton-wizard .estimation-plausibility--with-description .category-row .category-content {
  width: 70%;
}
#estimaton-wizard .estimation-plausibility--with-description .category-row .category-description {
  display: block;
  width: 30%;
}
#estimaton-wizard .estimation-plausibility--with-tableau .tableau-wrapper {
  position: static;
}
#estimaton-wizard .estimation-plausibility--with-tableau .categories-wrapper {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
#estimaton-wizard .icon-buttons {
  display: flex;
  justify-content: flex-end;
}
#estimaton-wizard .icon-buttons .button-group {
  margin-right: 100px;
}
#estimaton-wizard .icon-buttons .button-group button:nth-of-type(1) {
  border-right: 0;
}
#estimaton-wizard .icon-buttons .menu-button + .menu-button {
  margin-left: 100px;
}
#estimaton-wizard .tableau-select-reference-projects {
  /*
    Need to algin this element with tableau iframe. 
    iframe content has a fixed width of 1200px, in larger screen it will create horizontal padding 
  */
  padding-left: calc((100% - 1200px) / 2);
}
#estimaton-wizard .tableau-select-reference-projects--error {
  color: #900;
}
#estimaton-wizard .tableau-navigation {
  flex: 1 1;
  padding-top: calc(1rem + 40px);
}
#estimaton-wizard .tableau-navigation .button {
  padding: 10px;
}
#estimaton-wizard .tableau-navigation__button--active {
  background-color: #c1d0c8;
}
#estimaton-wizard .category-checkbox__label {
  display: flex;
}
#estimaton-wizard .category-checkbox__label--code {
  min-width: 20px;
}
#estimaton-wizard .category-checkbox__label--description {
  font-size: smaller;
}
#estimaton-wizard .category-description__with-text-area {
  padding: 1rem 1rem 1rem 0;
  background-color: #fff;
}
#estimaton-wizard .category-description__with-text-area .form__input {
  height: 100%;
  font-size: smaller;
}
#estimaton-wizard .category-description__with-text-area .form__input-field {
  height: 100%;
}

#dialog-container .data-collection .dialog__content {
  max-width: 90%;
}
#dialog-container .data-collection .dialog__content .dialog__body {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  font-size: 18px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#tableau-container {
  overflow: hidden;
  min-height: 1000px;
}
#tableau-container iframe {
  margin: 0 auto !important;
  min-height: 1000px;
}

ul {
  list-style: none;
  padding-left: 0;
}

h2 {
  margin-bottom: 0;
}

a {
  text-decoration: underline !important;
}

th,
td {
  vertical-align: middle !important;
}

#project-editor {
  height: 100%;
}
#project-editor > div.container {
  height: 100%;
}

.project-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-editor-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-editor-view-content {
  flex-grow: 1;
}

@media print {
  .header:has(+ main #estimaton-wizard),
.wrapper:has(#estimaton-wizard) + .footer,
#estimaton-wizard .wizard-nav,
#estimaton-wizard .status-indicator,
#estimaton-wizard button,
#estimaton-wizard .tableau-wrapper {
    display: none !important;
  }

  #estimaton-wizard .estimation-plausibility {
    padding-top: 15px !important;
  }
  #estimaton-wizard .categories-tableau-container {
    margin-top: 15px !important;
    width: 100% !important;
  }
  #estimaton-wizard .categories-tableau-container .category-content {
    width: 100% !important;
  }
  #estimaton-wizard .categories-tableau-container .category-row {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
