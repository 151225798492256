.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: $green;
  z-index: -1;
  transition: all 0.3s;
}

.sidebar-overlay--show {
  z-index: 999;
  opacity: 0.7;
}
