#project-editor {
  .project-list__page-selector {
    min-width: 100px;
  }

  .project-editor__action {
    color: $green;
    font-weight: bolder;
    cursor: pointer;
  }

  @import './project-editor/data-sheet';
  @import './project-editor/energy-sheet';
  @import './project-editor/building-sheet';
  @import './project-editor/media-sheet';
  @import './project-editor/cost-sheet';
  @import './project-editor/measurement-sheet';
  @import './project-editor/floors';
  @import './project-editor/project-list';
  @import './project-editor/create-project';
}
